import { connect } from 'react-redux';
import update from 'immutability-helper';
import { V5, STATISTICS, LIVE_REPORTS } from '../../common/path';
import { push, pushV5 } from '../../common/v5/utils';
import {
	updateOutboundPhoneId
	, updateAventaStatus
	, toggleCallPadPopup
	, updateAudioInput
	, sipMakeCallFromErrand
} from '../../redux/actions/call';
import {
	adminTag,
	changeAreaFilter,
	errandList,
	errandPreview,
	fetchAreaErrandCount,
	fetchAreaForwardAgents,
	fetchWorkflowExpertQueries,
	closeErrand,
	onceAdminTagSimpleList,
	uploadAnswerAttachment,
	removeTemporaryAttachment,
	queueToMe,
	forwardToArea,
	moveToFolder,
	forwardToAgent,
	returnToInbox,
	sendingBulkErrand,
	loadList,
	agentSetErrandView,
	forwardErrandsToArea,
	setErrandsPriority,
	setErrandsDueDate,
	stopPeriodicLoadList,
	workflowOnLoad,
	workflowAddAgentData,
	onceConnectedAgentAreas,
	stopNewIMCounters,
	setAgentDataFromLocalDb,
	decryptAndLoadErrand,
	fetchSlaTime,
	integrationCreateManualErrand
} from '../../redux/actions/async/workflow';
import {
	acceptChat
	, activateChatSourceConfig
	, changePickupNext
	, fetchAgentStatusList
	, optionalConfirm
	, stopPeriodicAgentStatus
	, stopPeriodicAutomaticLogout
	, updateAgentStatus
	, agentStatusOnLoad
	, agentPing
	, startPeriodicAutomaticLogout
	, fetchAgentFavourite
	, updateAgentFavourite
} from '../../redux/actions/async/hmf';
import { changeAccept
	, makeOutboundCall
	, makeOutboundAPICall
	, makeHangupAPICall
	, checkBrowser
	, onceOutboundPhones
} from '../../redux/actions/async/call';
import {
	makeCommonAPICall
	, playBeepNotif
	, onDtmfPress
	, sipGetAgentList
	, showExtTransferKeypad
	, toggleSipOutgoingPopup
	, resetCallTimer
} from '../../redux/actions/async/sippRtc';
import {
	changeArea,
	deleteUploadedAttachment,
	forwardMultipleErrandsToAgent,
	openErrandFromList,
	openReviewErrand,
	submitManualErrand,
	fetchPrintErrand,
	doDeleteErrand,
	doCloseErrand,
	putBackToInbox,
	linkErrand,
	uploadAgentAttachment,
	uploadOneAgentAttachment,
	fetchContactBook,
	backToListView,
	loadAndOpenErrand,
	promiseProcessManualErrandEmail,
	previewManualErrandEmailWithPost,
	fetchAgentWorkingOnSelectedErrands,
	acquireFromOtherAgentErrand,
	findAndOpenLastErrandInThread,
	loadAndOpenErrandFromSearch,
	finalizeErrandWarmTransfer,
	closeManualCallView,
	fetchAnnouncement
} from '../../redux/actions/async/errand';
import {
	changeAdminView,
	showAgentPreferences
} from '../../redux/actions/admin';
import {
	clearManualCallInputs,
	fetchQueueErrands
} from '../../redux/actions/workflow';
import {
	toggleCollaborationLight,
	multiDispatchesEE,
	fetchEEThread
} from '../../redux/actions/async/collaborate';
import {
	setupCollaborationQuery
} from '../../redux/actions/collaborate';
import { showInsertProtect } from '../../redux/actions/common';
import {
	togglePopAlert,
	toggleChatSource,
	showChatSourceConfig,
	toggleHeaderArrow,
	toggleShowAllNotification,
	popErrorOnly,
	setNotificationTab,
	toggleShowAnnouncement,
} from '../../redux/actions/hmf';
import {
	addFileArchiveToManual,
	deleteUploadedManualAttachment,
	manualFooterCheckboxes,
	onChangeRecipients,
	selectManualErrand,
	selectManualErrandCreation,
	selectManualSocialMediaAccount,
	selectManualReplyChannel,
	deleteKbAttachment
} from '../../redux/actions/manual';
import {
	contextChangeByFolder,
	selectErrandFromList,
	selectAllErrands,
	selectOrToggleSort,
	focusSort,
	selectTogglePriorityFilter,
	selectToggleAreasFilter,
	selectToggleAgentsFilter,
	selectToggleTagsFilter,
	changeContext,
	setSelectedFolder,
	selectToggleSideBar,
	selectCollapseSideBar,
	setSelectedAgent,
	setSelectedTags,
	setMobileView,
	setErrandMobileView,
	setListParams,
	switchToListView,
	toggleManualOrCallPopup,
	updatePrioritySort,
	resetErrandView,
	controlMultipleActions,
	setFilteredTags,
	setFilteredAreas,
	resetWorkflowFilter,
	setWorkflowReady,
	sendSessionReady,
	changeParamsThenReload,
	showAllArea,
	setPostMsgSubscribeErrandCount,
	resetWorkflowView,
	toggleWorkflowPopup
} from '../../redux/actions/workflow';
import {
	clearInputText,
	inputTextChanges,
	removeSelectedArchive,
	showUploadAttachment,
	tagsOperation,
	setPersonalization,
	setPersonalizationDefault,
	showContactBook,
	setReplyAddressType,
	setManualErrandTranslateTo,
	showErrandLibraryPopup,
	updateTweetWarning,
	selectManualReply,
	selectManualCallReply,
	toggleErrandChatSidePanel,
	setGridFolderIndex,
	resetAgentSatisfaction,
	toggleWaTemplPopup,
	setWaTemplContent,
	setPreviousErrand,
	saveWATemplateCode,
	expandHideOption,
	expandCollabQueryThread,
	selectShowReply,
	doExpandKnowledgeBasePanel
} from '../../redux/actions/errand';
import {
	isManualPopup,
	isManualCallPopup,
	getManualErrandAreasMemoize,
	getOutboundPhoneTo,
	manualAttachmentsSelector,
	manualAttachmentSizeSelector,
	manualButtonsSelector,
	manualCheckboxesSelector,
	manualCheckboxStatesSelector,
	manualErrandAreaSalutationsSelector,
	manualErrandAreaSignaturesSelector,
	manualErrandAreaTagsSelector,
	manualErrandAreaTemplatesSelector,
	manualErrandChannelsSelector,
	manualErrandFromOptionsSelector,
	manualErrandRecipientsSelector,
	manualErrandSelectedAreaSelector,
	manualErrandSelectedReplyChannel,
	manualErrandSelectedTagsSelector,
	manualFileArchivesSelector,
	manualRecipientsOptionsSelector,
	selectedAccountMemoize,
	showManualSelector,
	showManualCallSelector,
	manualErrandCreateAsSelector,
	manualCallMinimize,
	getCallCreatedId,
	isCallMemoize,
	showBulkSendSelector
} from '../../redux/selectors/manual';
import {
	manualCkeditorInsertionTextMemoize
} from '../../redux/selectors/cke';
import {
	isTwilioCallEnabled
	, featureSelector as serverFeatureSelector
} from '../../redux/selectors/server';
import {
	getCurrentErrandAreaData,
	getCurrentOpenErrandSelector,
	previewPersonalization,
	isManualPreviewDisabledSelector,
	showPreviewSelector,
	getCurrentType,
	getAgentWorking,
	agentTimezoneOffsetSelector,
	getCurrentErrandId,
	getPreviousErrandId,
	getClassificationTagsSelector,
	getEmailRecipients
} from '../../redux/selectors/errand';
import {
	allAreasSelector,
	attachFileOptionsSelector,
	contextAwareActiveAreasMemo,
	contextAwareOrgAreasMemo,
	contextMemo,
	currentContextFromInputList,
	forwardErrandsAgentsSelector,
	getAllowLightBulbOff,
	getCollaborationInfoSelector,
	getTotalSelectedErrands,
	isLoadingFwdErrandsAgentsSelector,
	actionsSelectedErrandsSelector,
	getPreviewFeatures,
	myErrandFolderMemoize,
	getCurrentAreasSelector,
	getErrandAllSelected,
	getChatErrandSelected,
	getHasDueDateSelected,
	onlyActiveAgentsSelector,
	selectedFilterAreasMemoize,
	selectedFilterOrgsMemoize,
	serverChannelsSelector,
	tagsFilterSelector,
	areaFilterSelector,
	agentFilterSelector,
	searchListSelector,
	totalSearchResult,
	myDefaultFolderMemoize,
	getSlaTimeSelector,
	vipTagsSelector,
	getVIPListCounters,
	getListViewData
} from '../../redux/selectors/workflow';
import {
	postponeFolderEnabledMemo,
	postponedSelector,
	reviewFolderEnabledMemo
	, reviewSelector
} from '../../redux/selectors/review';
import {
	getSelectedSearchErrandCount
	, isFromSearch
} from '../../redux/selectors/search';
import {
	acceptCallMemoize
	, sipCallStatus
	, sipCallIsRecording
	, sipCallConn
	, isCallPadPopupShown
	, callStatusMemoize
	, sipCallIncomingOngoing
	, sipCallTimer
	, sipGetCurrentEid
	, sipCallTransferStatus
	, sipCallCurrentTransferMode
	, sipMakeCallCurrentErrand
	, sipCallTransferIsExternal
} from '../../redux/selectors/call';
import {
	ManualErrandCanTranslate,
	ManualErrandTranslateTo
} from '../../redux/selectors/translation';
import { isMobileSelector } from '../../redux/selectors/mobile';
import {
	ManualErrand
} from '../../components/v5/ManualErrand';
import {
	AT_ARCHIVE,
	AT_UPLOADED,
	CTX_REVIEW,
	ME_CREATE,
	MESSAGE_INVALID_EMAIL,
	MESSAGE_INVALID_PHONE_NO,
	MP_NONE,
	MP_MINIMIZE,
	RC_EMAIL,
	RC_SMS,
	RC_VOICE,
	ME_START,
	RPLY_MANUAL,
	UI_SHOW_OLD_CHAT,
	PREF_LIST_VIEW,
	PREF_CONVERSATION_VIEW,
	MY_ERRANDS,
	NEW_ERRANDS,
	AT_LIBRARY,
	CB_CONTACT_CARD,
	SEARCH_ERRANDS,
	SEARCH_MY_ERRANDS,
	SEARCH_NEW_ERRANDS,
	SEARCH_CLOSE_ERRANDS,
	FILTER_SEARCH_ERRAND_EMPTY,
	FILTER_SEARCH_ERRAND_MY,
	FILTER_SEARCH_ERRAND_ALL,
	FILTER_SEARCH_ERRAND_CLOSE,
	RPLY_ERRAND,
	emptyObject,
	VIP_ERRANDS,
	ACCOUNTS_VIEW_SLICE,
	CTX_POSTPONED,
	RPLY_COLLABORATE,
	WFP_BULK_SEND_ERRAND
} from '../../common/v5/constants';
import {
	SIP_CALL_IDLE
	, CALL_STR_HOLD
} from '../../common/v5/callConstants'
import {
    getExtQueueType
} from '../../common/v5/helpers';
import {
	startRecordingSip
	, stopRecordingSip
	, pauseRecordingSip
} from '../../common/v5/webRtcVoice';
import {
	GLOBAL_SEARCH_FROM_BODY,
	SEARCH_ERRAND,
	GLOBAL_SEARCH_FROM_HEADER
} from '../../redux/constants/constants';
import { mcamByID, throttledDispatch } from '../../redux/util';
import {
	handlePagination,
	handleResetOffset,
	handleFilterByLabel,
	doGlobalSearchByWS,
	handleSearchSpinner,
	handleSearchTextChange,
	handleHeaderSearchHide
} from '../../redux/actions/search';
import {default as WorkflowNew, WorkflowInnerContainer} from './workflow';
import SidebarSettings, { IconizeSideBarMenu } from '../../components/v5/SidebarSettings';
import TopWorkflowFilter from '../../components/v5/TopFilter';
import WorkflowBreadcrumbs from '../../components/v5/WorkflowBreadcrumbs';
import {
	clearInsertionContent, insertContent
} from '../../redux/actions/async/cke';
import {
	customConfirm
} from '../../redux/actions/async/hmf';
import {
	agentNameSelector,
	agentStatusSelector,
	getAgentStatusData,
	getAgentStatusById,
	getAgentStatusByName,
	isMainMenuWorkflowErrandSelector
} from '../../redux/selectors/hmf';
import {
	cancellableReviewClick
	, exitReview
	, openReviewFolder
	, pushReviewErrandURL
} from '../../redux/actions/async/review';
import { IsContextSearch } from '../../common/v5/utils';
import {
	openSingleIM
} from "../../redux/actions/async/internalMessages";
import {
	statisticsOnLoad,
	loadSavedReport
} from "../../redux/actions/async/statistics";
import { wsEvent } from '../../redux/actions/websocket';
import { changeView } from '../../redux/actions/statistics';

const mapMErd = (state, props) => {
	const e = state.app.errand
		, m = e.ui.manual
		, mc = e.ui.manualCall
		, inpt = isCallMemoize(state) ? e.manualCallInputs : e.manualInputs
		, eInpt = e.inputs
		, tagsByArea = state.domain.areas.byId[eInpt.area]
		, wf = state.app.workflow
		, reply = e.ui.reply
		, einpt = e.inputs
		, s = {
			archives: manualFileArchivesSelector(state, props),
			attachFileOptions: attachFileOptionsSelector(state, props),
			attachments: manualAttachmentsSelector(state, props),
			attachmentSize: manualAttachmentSizeSelector(state, props),
			createdId: (isCallMemoize(state) ? mc.createdId : m.createdId),
			newErrandId: (isCallMemoize(state) ? mc.newErrandId : m.newErrandId),
			mCipherKey: (isCallMemoize(state) ? mc.cipherKey : m.cipherKey),
			footerCheckboxes: manualCheckboxesSelector(state, props),
			footerCheckboxStates: manualCheckboxStatesSelector(state, props),
			mobile: isMobileSelector(state, props),
			phone: getOutboundPhoneTo(state, props),
			state: m.state,
			callState: mc.state,
			sipCallStatus: sipCallStatus(state),
			sipCallIsRecording: sipCallIsRecording(state),
			sipPhone: sipCallConn(state),
			sipIncomingOngoing: sipCallIncomingOngoing(state),
			selectedTab: (isCallMemoize(state) ? mc.tab : m.tab),
			show: showManualSelector(state, props),
			showCall: showManualCallSelector(state, props),
			showBulkSend: showBulkSendSelector(state),
			wfSettings: wf.fetchWfSettings.data,
			// NOTE: purposely make this state share between manual and
			// answer box. Anytime can make this manual specific only.
			showUploadAttachmentBox: e.ui.reply.showUploadAttachmentBox,
			uploading: false,
			createAs: manualErrandCreateAsSelector(state, props),
			content: inpt.update_answer,
			comment: inpt.internal_comment,
			subject: sipMakeCallCurrentErrand(state) ? eInpt.update_subject : inpt.update_subject,
			updateTo: inpt.update_to,
			currentReplyOption: (isCallMemoize(state) ? mc.currentReply : m.currentReply),
			areas: getManualErrandAreasMemoize(state),
			selectedArea: sipMakeCallCurrentErrand(state) ? eInpt.area : manualErrandSelectedAreaSelector(state, props),
			channels: manualErrandChannelsSelector(state, props),
			selectedChannel: manualErrandSelectedReplyChannel(state, props),
			tags: sipMakeCallCurrentErrand(state) ? (tagsByArea ? tagsByArea.normal_tags : []) : manualErrandAreaTagsSelector(state, props),
			selectedTags: sipMakeCallCurrentErrand(state) ? eInpt.tags : manualErrandSelectedTagsSelector(state, props),
			recipients: manualErrandRecipientsSelector(state, props),
			selectedSubject: inpt.selected_indexes.subject,
			selectedAccount: selectedAccountMemoize(state),
			fromOptions: manualErrandFromOptionsSelector(state, props),
			recipientOptions: manualRecipientsOptionsSelector(state, props),
			buttonsState: manualButtonsSelector(state, props),
			lastSavedTimestamp: '',
			isModified: false,
			salutations: manualErrandAreaSalutationsSelector(state, props),
			signatures: manualErrandAreaSignaturesSelector(state, props),
			templates: manualErrandAreaTemplatesSelector(state, props),
			selectedSig: inpt.update_signature,
			selectedSal: inpt.update_salutation,
			defaultSig: inpt.update_signature_default,
			defaultSal: inpt.update_salutation_default,
			answer: sipMakeCallCurrentErrand(state) ? eInpt.update_answer : inpt.update_answer,
			plain: sipMakeCallCurrentErrand(state) ? eInpt.plain_answer : inpt.plain_answer,
			canTranslate: ManualErrandCanTranslate(state, props),
			toLang: ManualErrandTranslateTo(state, props),
			openKnowledgeBase: e.ui.knowledgeBase.showPopup,
			shouldShowTweetWarning: reply.shouldShowTweetWarning,
			numberOfTweet: reply.numberOfTweet,
			insertionText: manualCkeditorInsertionTextMemoize(state),
			previewDisabled: isManualPreviewDisabledSelector(state),
			showPreview: showPreviewSelector(state),
			currentSelected: getCurrentType(state, props),
			showWATemplatePopup: reply.showWATemplatePopup,
			templContent: einpt.templContent,
			templCode: einpt.templCode,
			waTemplId: einpt.templId,
			sipCallTimer: sipCallTimer(state),
			sipCurrentEid: sipGetCurrentEid(state),
			sipCallTransferStatus: sipCallTransferStatus(state),
			sipXferMode: sipCallCurrentTransferMode(state),
			sipMakeCallCurrentErrand: sipMakeCallCurrentErrand(state),
			sipCallTransferIsExternal: sipCallTransferIsExternal(state),
			openedErrandId: getCurrentErrandId(state),
			expandKnowledgeBasePanel: e.ui.expandKnowledgeBasePanel,
		};
	return s;
};

const warnDeleteAttachment = I("Are you sure you want to delete attachment ")
	, warnBttnYes = 1
	, warnBttnNo = 2
	, yesBttnValue = {
		type: warnBttnYes
		, color: 'grey'
		, text: I('Yes')
	}
	, noBttnValue = {
		type: warnBttnNo
		, color: 'blue'
		, text: I('No')
	}
	, warnYesOrNoButtons = [
		yesBttnValue
		, noBttnValue
	]
	, shouldDeleteAttachment = {shouldDeleteAttachment: true}
	;

function confirmDeleteAttachment(dispatch, reply, type, attachmentID, attachmentFileName) {
	new Promise((resolve, reject) => {
		let warnBttns = warnYesOrNoButtons;
		resolve(dispatch(customConfirm(
			warnDeleteAttachment + attachmentFileName + "?"
			, warnBttns,
		))
		.then(({ button }) => {
			if (button === warnBttnYes) {
				return shouldDeleteAttachment;
			}
		}));
	})
	.then(shouldDeleteAttachment => {
		if(shouldDeleteAttachment) {
			if(type === AT_ARCHIVE) {
				return dispatch(removeSelectedArchive(RPLY_MANUAL,
					attachmentID));
			} else if(type === AT_UPLOADED) {
				return dispatch(deleteUploadedAttachment(reply,
					attachmentID));
			} else if(type === AT_LIBRARY) {
				return dispatch(deleteKbAttachment(RPLY_MANUAL,
					attachmentID));
			}
		}
	});
}

const throttledCallClick = throttledDispatch(makeOutboundCall, 750);

const mapMErdCallbacks = (dispatch, props) => {
	return {
		onAttachmentDelete: (reply, type, attachmentID, index, e, attachmentFileName) => {
			confirmDeleteAttachment(dispatch, reply, type, attachmentID, attachmentFileName);
		},
		onCallClick: phone => {
			// console.log("dbg: call-click:", phone);
			dispatch(makeOutboundCall());
		},
		onApiCallClick: (phone, updateSubject) => {
			if(updateSubject){
				dispatch(inputTextChanges('update_subject', phone,
					RPLY_MANUAL));
			}
			dispatch(makeOutboundAPICall(true));
		},
		onApiHangupClick: phone => {
			dispatch(makeHangupAPICall());
		},
		handleDtmf: (phone, key) => {
			dispatch(onDtmfPress(phone,key));
		},
		onCommonCallAPI: (type,phone, isHold, isManual) => {
			dispatch(makeCommonAPICall(type, phone, isHold, 0, "","", false,
				"", isManual));
			if(type == CALL_STR_HOLD){
				playBeepNotif();
			}
		},
		recordSipCall: (eid, sipPhone, doRecording) =>{
			if(sipPhone != null){
				if(sipPhone.conn.backendRecording == true){
					sipPhone.conn.pauseBackendSipRecording(doRecording);
					return;
				}
				if(sipPhone.conn.autoRecording == true){
					playBeepNotif();
					dispatch(pauseRecordingSip());
					return;
				}
				if(doRecording){
					dispatch(startRecordingSip(eid,
						sipPhone.conn.getStreams(), true))
				} else {
					dispatch(stopRecordingSip(eid,
						sipPhone.conn.getStreams()))
				}
			}
		},
		answerTransferCall: (conn, eid) =>{
			dispatch(sipGetAgentList(conn, eid));
			if(features['sip.allow-external-forward']) {
				dispatch(showContactBook(true));
				dispatch(fetchContactBook(""));
				dispatch(setReplyAddressType("", RPLY_MANUAL));
				if(features['sip.show-forward-dialer']) {
					dispatch(showExtTransferKeypad(true));
				}
			}
		},
		finalizeWarmTransferToAgent: (eid, displayId, conn,
			mCipherKey, isManual, isExternalTransfer) => {
			dispatch(finalizeErrandWarmTransfer(eid, displayId, conn,
			mCipherKey, isManual,isExternalTransfer));
		},
		onDelayCallClick: () => {
			// wait redux phone value occupied after accepting the input value
			// as confirm phone call then only trigger call.
			// console.log("dbg: delay-call-click");
			// TODO: improve this to make use of promise instead of playing dice
			// hopefully 750ms enough for browser to set the state for phone
			// number.
			dispatch(throttledCallClick);
		},
		onDragnDropFile: (reply, data, info) => {
			dispatch(showUploadAttachment(true));
			return dispatch(uploadOneAgentAttachment({data, info}, reply, false));
		},
		onFailValidation: (type, input) => {
			if (type === RC_EMAIL) {
				dispatch(togglePopAlert(MESSAGE_INVALID_EMAIL + ' ' + input));
			} else if (type === RC_SMS || type === RC_VOICE) {
				dispatch(togglePopAlert(MESSAGE_INVALID_PHONE_NO + ' ' +
					input));
			} else {
				dispatch(togglePopAlert('invalid input: '+input));
			}
		},
		onFileupload: (file, route, showAttachment, reply) => {
			dispatch(uploadAgentAttachment(file, route, reply));
		},
		onOpenInsertProtectedContentReply: () => dispatch(showInsertProtect(true)),
		onSelectAccount: (type, dbID, accountID, value) => {
			dispatch(selectManualSocialMediaAccount(type, dbID, accountID,
				value));
		},
		onSelectPhone: (id, phone) => {
			if (process.env.NODE_ENV !== 'production') {
				console.log("dbg: selected phone:", {id, phone});
			}
			dispatch(updateOutboundPhoneId(id));
		},
		onSelectTab: value => {
			dispatch(selectManualErrand(value));
			dispatch(selectManualReply(value));
		},
		onSelectReplyOption: (value) => {
			dispatch(selectManualReply(value));
		},
		onSelectCallReplyOption: (value) => {
			dispatch(selectManualCallReply(value));
		},
		onSelectCreation: value => {
			dispatch(selectManualErrandCreation(value));
		},
		onSelectChannel: channel => {
			dispatch(selectManualReplyChannel(channel));
		},
		onSubjectChange: (e, currentErrand) => {
			if(currentErrand) {
				dispatch(inputTextChanges('update_subject', e.target.value));
			} else {
				dispatch(inputTextChanges('update_subject', e.target.value,
				RPLY_MANUAL));
			}
		},
		onUploadArchive: (id, archiveFiles) => {
			dispatch(addFileArchiveToManual(id, archiveFiles));
		},
		onClose: (isCall, sipCall, erdCreated, isCurrentErrand) => {
			if(sipCall && erdCreated) {
				if(!isCurrentErrand){
					dispatch(closeManualCallView());
				}
			}else {
				if(isCall) {
					dispatch(toggleManualOrCallPopup(MP_NONE, true));
					dispatch(clearManualCallInputs());
					if(isCurrentErrand) {
						dispatch(sipMakeCallFromErrand(false, 0));
					}
				} else {
					dispatch(toggleManualOrCallPopup(MP_NONE, false));
					dispatch(toggleWorkflowPopup(WFP_BULK_SEND_ERRAND, false));
				}
			}
		},
		onContentChange: (which, html, plain, currentErrand) => {
			if(currentErrand) {
				dispatch(inputTextChanges(which, html, plain, RPLY_ERRAND));
			}
			dispatch(inputTextChanges(which, html, plain, RPLY_MANUAL));
		},
		onRecipientsChange: (which, recipients) => {
			// dispatch(recipientsChange(which, recipients, RPLY_MANUAL));
			dispatch(onChangeRecipients(which, recipients));
		},
		onFromChange: (which, recipients) => {
			console.log("dbg: FROM change:", which, recipients);
		},
		onAreaChange: value => {
			dispatch(changeArea(value, true));
		},
		onTagChange: (opr, value) => {
			dispatch(tagsOperation(opr, value, true));
		},
		onClear: (which, isCall, currentErrand) => {
			if(currentErrand) {
				dispatch(clearInputText(which));
			} else {
				dispatch(clearInputText(which, RPLY_MANUAL, isCall));
			}
		},
		onSubmit: (type, createType, forceUploadedAttachments,
			isSip, isBulkSend) => {
			if(isBulkSend) {
				dispatch(sendingBulkErrand())
				.then(() => {
					dispatch(toggleWorkflowPopup(WFP_BULK_SEND_ERRAND, false));
				});
			} else {
				dispatch(submitManualErrand(type, createType,
					forceUploadedAttachments, isSip, false));
			}
		},
		toggleFooterCheckboxClick: (currentRoot, me, type, state) => {
			if(currentRoot === ME_CREATE) {
				// do nothing whenn it is in creating manual and not sending.
				return;
			} else {
				dispatch(manualFooterCheckboxes(type, state));
			}
		},
		toggleUploadAttachment: currentShowState => {
			dispatch(showUploadAttachment(!currentShowState));
		},
		setSignature: id => {
			dispatch(setPersonalization("update_signature", id, RPLY_MANUAL))
		},
		setSalutation: id => {
			dispatch(setPersonalization("update_salutation", id, RPLY_MANUAL))
		},
		setSignatureDefault: () => {
			dispatch(setPersonalizationDefault("update_signature_default", false, RPLY_MANUAL))
		},
		setSalutationDefault: () => {
			dispatch(setPersonalizationDefault("update_salutation_default", false, RPLY_MANUAL))
		},
		appendTemplate: (html) => {
			dispatch(insertContent(html));
		},
		saveWATemplateCode: (value) => {
			dispatch(saveWATemplateCode(value))
		},
		onShowTemplateCustom: (tgl, wc, id) =>{
			dispatch(toggleWaTemplPopup(tgl));
			dispatch(setWaTemplContent(wc, id));
		},
		handleWATemplToggle: tgl => {
			dispatch(toggleWaTemplPopup(tgl));
		},
		onToggleContactBook: (tgl, replyType) => {
			dispatch(showContactBook(tgl));
			dispatch(setReplyAddressType(replyType, RPLY_MANUAL));
			dispatch(fetchContactBook(""));
		},
		onSelectedToLang: (l, reply) => {
			dispatch(setManualErrandTranslateTo(l));
		},
		onToggleKnowledgeBase: (tgl) =>{
			if(cflag.IsActive("2024-02-20.CEN-1713.new-knowledgebase-gui") && cflag.IsActive("2024-05-23.CEN-2090.new-errand-knowledgebase-gui")){
				dispatch(doExpandKnowledgeBasePanel(tgl));
			} else {
				dispatch(showErrandLibraryPopup(tgl, RPLY_MANUAL));
			}
		},
		onCloseKnowledgeBasePopup: () =>{
			dispatch(doExpandKnowledgeBasePanel(false));
		},
		showTweetWarning: (toggle, nt) =>{
			dispatch(updateTweetWarning(toggle, nt));
		},
		onResetInsertionText: () => {
			dispatch(clearInsertionContent());
		},
		onClickPreview: (type, createType) => {
			promiseProcessManualErrandEmail(dispatch, RPLY_ERRAND, type, createType, previewManualErrandEmailWithPost);
		},
		onMinimizeCall: (contact) => {
			dispatch(toggleWorkflowPopup(MP_MINIMIZE));
			dispatch(toggleSipOutgoingPopup(true, false, contact));
		},
		resetSipTimer: (val) =>{
			dispatch(resetCallTimer(val));
		}
	};
};

export const ManualErrandModalCtnr = connect(
	mapMErd
	, mapMErdCallbacks
)(ManualErrand);

var typingTimer;
var doneTypingInterval = 500;

const getPage = store =>{
	let route = store.router;
	if(typeof route !== 'undefined'){
		if(typeof route.location !== 'undefined' && route.location.pathname){
			return route.location.pathname;
		}
	}
	return "";
}

const mapSidebarSettings = (state, props) => {
	let enablePickupNext = true;
	enablePickupNext = serverFeatureSelector(state, props)['enablePickUpNext'];
	let wf = state.app.workflow
		, header = state.app.header
		, search = state.app.search
		, s = {
			pickUpNext: header.uiData.pickUpNext,
			enablePickupNext: enablePickupNext,
			forcePickupNext: serverFeatureSelector(state, props)['forced-pickup-next'],
			chatEnabled: serverFeatureSelector(state, props).chat,
			acceptCall: acceptCallMemoize(state),
			acceptChat: header.acceptChat.accept,
			chatConfig: header.chatSource,
			chatSource: wf.fetchWfSettings.data.chatSource,
			isManualPopup: isManualPopup(state),
			isManualCallPopup: isManualCallPopup(state),
			showOldChatUI: wf.ui[UI_SHOW_OLD_CHAT],
			callEnabled: isTwilioCallEnabled(state),
			aventaEnabled: wf.fetchWfSettings.data.aventaEnabled,
			sipServerUrl: wf.fetchWfSettings.data.sipServerUrl,
			sipEnabled: wf.fetchWfSettings.data.sipEnabled,
			sipCallStatus: sipCallStatus(state),
			sipMinimizeMode: manualCallMinimize(state),
			chatConfigOnlineStatus: header.uiData.chatSourceStatus,
			totalChatChannel: header.uiData.totalChannel,
			totalChatChannelActive: header.uiData.totalChannelActive,
			collapseSideBar: wf.ui.collapseSideBar,
			mobileView: wf.ui.showMobileView,
			windowInnerHeight: wf.ui.windowInnerHeight,
			chatConfigOpen: header.uiData.showChatSourceConfig,
			pageAddress: getPage(state),
			isSearching: search.inputs.searchOngoing,
			searchResults: search.results,
			searchText: search.inputs.headerText,
			showResultBox: search.inputs.showResultBox,
			disableActiveSearch: wf.fetchWfSettings.data.disableActiveSearch,
			notification: state.app.notification,
			uiData: state.app.header.uiData,
			agentTimezoneOffset: agentTimezoneOffsetSelector(state),
			menu: state.server.menu.rightMenu,
			agent: agentNameSelector(state),
			newIMCount: state.app.internalMessage.counters.newIM.count,
			agentStatus: agentStatusSelector(state),
			agentStatusById: getAgentStatusById(state),
			agentStatusByName: getAgentStatusByName(state),
			agentStatusList: getAgentStatusData(state)
		};
	return s;
};

const mapSidebarSettingsCallbacks = (dispatch, props) => {
	return {
		onChangeAcceptCall: accept => {
			dispatch(changeAccept(accept));
		},
		onChangeManualPopup: (value) => {
			dispatch(toggleManualOrCallPopup(value, false));
		},
		onChangeManualCallPopup: (value) => {
			dispatch(toggleManualOrCallPopup(value, true));
		},
		onHandleChatConfig: (open) => {
			dispatch(activateChatSourceConfig(open));
		},
		onHandleOpenChatConfig: (open) => {
			dispatch(showChatSourceConfig(open));
		},
		onTogglePickUpNext: (active) => {
			dispatch(changePickupNext(active));
		},
		loadAgentStatusList: () => {
			dispatch(fetchAgentStatusList())
		},
		handleKeyUpSearchBox: (e, setHeaderDropdown)=>{
			if (typingTimer) clearTimeout(typingTimer);
			if(e.target.value !== ""){
				dispatch(handleSearchSpinner(true));
				typingTimer = setTimeout(()=>{
					dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_HEADER,
						setHeaderDropdown));
				}, doneTypingInterval);
			}
		},
		handleKeyDownSearchBox: (e) =>{
			clearTimeout(typingTimer);
			if(e.keyCode === 8 || e.keyCode === 46){ //backspace, delete
				dispatch(handleSearchTextChange('header', e.target.value));
			}else if(e.keyCode === 27){ //esc
				dispatch(handleSearchTextChange('header', ""));
				dispatch(handleHeaderSearchHide());
			}
		},
		handleChangeSearchBox: (e) =>{
			e.preventDefault();
			let val = e.target.value;
			dispatch(handleSearchTextChange('header',val));
		},
		handleSearchButton: (e, val) =>{
			console.log('button',e);
			dispatch(handleSearchSpinner(true));
			dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_HEADER, true));
		},
		handleHeaderSearchDD: (val) =>{
			dispatch(handleHeaderSearchHide(val));
		},
		openErrandFromSearch: (id) => {
			dispatch(loadAndOpenErrandFromSearch(id));
			dispatch(handleHeaderSearchHide());
		},
		openErrand: (id) => {
			dispatch(loadAndOpenErrand(id));
			dispatch(handleHeaderSearchHide());
		},
		onToggleArrow: (key) => {
			dispatch(toggleHeaderArrow(key));
		},
		onShowAllNotification: () => {
			dispatch(toggleShowAllNotification(true));
			dispatch(toggleShowAnnouncement(false));
		},
		OpenLastErrandInThread: (id) => {
			dispatch(findAndOpenLastErrandInThread(id));
			dispatch(handleHeaderSearchHide());
		},
		openCollaReplyPanel: (eId, tId, qId, isReply) => {
			dispatch(loadAndOpenErrand(eId))
			.then(() => {
				dispatch(expandHideOption('collaboration', eId, true));
				dispatch(multiDispatchesEE(eId))
				.then(() => {
					dispatch(fetchEEThread(tId, eId)).then(() => {
						dispatch(expandCollabQueryThread(tId, true, qId));
						if(!isReply){
							dispatch(setupCollaborationQuery(tId, qId));
						} else {
							dispatch(selectShowReply(RPLY_COLLABORATE,true));
						}
					});
				})

			});
			dispatch(handleHeaderSearchHide());
		},
		openMessage: (id) => {
			dispatch(openSingleIM(id));
			dispatch(handleHeaderSearchHide());
		},
		openStatistics: id => {
			dispatch(statisticsOnLoad())
			.then(r => {
				dispatch(loadSavedReport(id));
			});
		},
		openAccounts: (id, link) => {
			let view = ACCOUNTS_VIEW_SLICE[id];
			dispatch(push(link)).then(() => dispatch(changeAdminView(view)));
		},
		onLinkChange: (id, link) => dispatch(push(link)).
			then(() => dispatch(changeAdminView(id))),
		onClickPreferences: () => dispatch(showAgentPreferences()),
		onUnload: () => {
			console.log("dbg: header can not unmount at v5 page. check ws.");
			dispatch(stopPeriodicAgentStatus());
			dispatch(stopPeriodicAutomaticLogout())
		},
		onUpdateStatus: (updateStatus) => {
			console.log("debug: on update status", updateStatus);
			dispatch(updateAgentStatus(updateStatus));
		},
		onStatusLoad: (isAventa) => {
			dispatch(agentStatusOnLoad())
			.then(data =>{
				if(isAventa){
					if(data != "loggedout"){
						dispatch(toggleChatSource(
								Workflow.Errand.SERVICE_AVENTA, true));
					}
				}
			});
			if(autoLogout != undefined && autoLogout.enabled ){
				dispatch(agentPing())
				.then(() => {
					dispatch(startPeriodicAutomaticLogout())
				})
			}
		},
		onWSEvent: (packet) => {
			dispatch(wsEvent(packet));
		},
		onClickCallIcon: showState => {
			dispatch(toggleCallPadPopup(showState));
		},
		onSetNotificationTab:(tab) => {
			dispatch(setNotificationTab(tab))
		},
		openAnnouncement: (id) => {
			dispatch(fetchAnnouncement(id));
			dispatch(toggleShowAnnouncement(true));
		}
	};
};

export const SidebarSettingsCtnr = connect(mapSidebarSettings,
	mapSidebarSettingsCallbacks)(SidebarSettings);

const mapIconizeSideBarMenu = (state, props) => {
	let wf = state.app.workflow
		, search = state.app.search
		, s = {
			isSearching: search.inputs.searchOngoing,
			searchResults: search.results,
			searchText: search.inputs.headerText,
			showResultBox: search.inputs.showResultBox,
			disableActiveSearch: wf.fetchWfSettings.data.disableActiveSearch,
			notification: state.app.notification,
			uiData: state.app.header.uiData,
			agentTimezoneOffset: agentTimezoneOffsetSelector(state),
			menu: state.server.menu.rightMenu,
			agent: agentNameSelector(state),
			newIMCount: state.app.internalMessage.counters.newIM.count,
			agentStatus: agentStatusSelector(state),
			agentStatusById: getAgentStatusById(state),
			agentStatusByName: getAgentStatusByName(state),
			agentStatusList: getAgentStatusData(state),
			callEnabled: isTwilioCallEnabled(state)
		};
	return s;
};


const mapIconizeSideBarMenuCallbacks = (dispatch, props) => {
	return {
		handleKeyUpSearchBox: (e, setHeaderDropdown)=>{
			if (typingTimer) clearTimeout(typingTimer);
			if(e.target.value !== ""){
				dispatch(handleSearchSpinner(true));
				typingTimer = setTimeout(()=>{
					dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_HEADER,
						setHeaderDropdown));
				}, doneTypingInterval);
			}
		},
		handleKeyDownSearchBox: (e) =>{
			clearTimeout(typingTimer);
			if(e.keyCode === 8 || e.keyCode === 46){ //backspace, delete
				dispatch(handleSearchTextChange('header', e.target.value));
			}else if(e.keyCode === 27){ //esc
				dispatch(handleSearchTextChange('header', ""));
				dispatch(handleHeaderSearchHide());
			}
		},
		onChangePage: (url, id) => {
			dispatch(push(url)).then(() => dispatch(changeAdminView(id)));
		},
		handleChangeSearchBox: (e) =>{
			e.preventDefault();
			let val = e.target.value;
			dispatch(handleSearchTextChange('header',val));
		},
		handleSearchButton: (e, val) =>{
			console.log('button',e);
			dispatch(handleSearchSpinner(true));
			dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_HEADER, true));
		},
		handleHeaderSearchDD: (val) =>{
			dispatch(handleHeaderSearchHide(val));
		},
		openErrand: (id) => {
			dispatch(loadAndOpenErrand(id));
			dispatch(loadAndOpenErrandFromSearch(id));
		},
		openErrandFromSearch: (id) => {
			dispatch(loadAndOpenErrandFromSearch(id));
		},
		onToggleArrow: (key) => {
			dispatch(toggleHeaderArrow(key));
		},
		onShowAllNotification: () => {
			dispatch(toggleShowAllNotification(true));
			dispatch(toggleShowAnnouncement(false));
		},
		OpenLastErrandInThread: (id) => {
			dispatch(findAndOpenLastErrandInThread(id));
			dispatch(handleHeaderSearchHide());
		},
		openCollaReplyPanel: (eId, tId, qId, isReply) => {
			dispatch(loadAndOpenErrand(eId))
			.then(() => {
				dispatch(expandHideOption('collaboration', eId, true));
				dispatch(multiDispatchesEE(eId))
				.then(() => {
					dispatch(fetchEEThread(tId, eId)).then(() => {
						dispatch(expandCollabQueryThread(tId, true));
						if(!isReply){
							dispatch(setupCollaborationQuery(tId, qId));
						} else {
							dispatch(selectShowReply(RPLY_COLLABORATE,true));
						}
					});
				})

			});
			dispatch(handleHeaderSearchHide());
		},
		openMessage: (id) => {
			dispatch(openSingleIM(id));
			dispatch(handleHeaderSearchHide());
		},
		openStatistics: id => {
			dispatch(statisticsOnLoad())
			.then(r => {
				dispatch(loadSavedReport(id));
			});
		},
		openAccounts: (id, link) => {
			let view = ACCOUNTS_VIEW_SLICE[id];
			dispatch(push(link)).then(() => dispatch(changeAdminView(view)));
		},
		onLinkChange: (id, link) => {
			dispatch(push(link)).then(() => dispatch(changeAdminView(id)))
		},
		onClickPreferences: () => dispatch(showAgentPreferences()),
		onUnload: () => {
			console.log("dbg: header can not unmount at v5 page. check ws.");
			dispatch(stopPeriodicAgentStatus());
			dispatch(stopPeriodicAutomaticLogout())
		},
		onUpdateStatus: (updateStatus) => {
			console.log("debug: on update status", updateStatus);
			dispatch(updateAgentStatus(updateStatus));
		},
		onStatusLoad: (isAventa) => {
			dispatch(agentStatusOnLoad())
			.then(data =>{
				if(isAventa){
					if(data != "loggedout"){
						dispatch(toggleChatSource(
								Workflow.Errand.SERVICE_AVENTA, true));
					}
				}
			});
			if(autoLogout != undefined && autoLogout.enabled ){
				dispatch(agentPing())
				.then(() => {
					dispatch(startPeriodicAutomaticLogout())
				})
			}
		},
		onLoadCall: ws => dispatch(checkBrowser(ws)).then(audioInputListS => {
			if (audioInputListS) {
				dispatch(onceOutboundPhones());
			}
				return audioInputListS;
		}),
		onWSEvent: (packet) => {
			dispatch(wsEvent(packet));
		},
		onChangeView: (view) => {
			dispatch(changeView(view));
		},
		onOpenLiveReportsAndRedirectView: (view) => {
			dispatch(push(LIVE_REPORTS))
			.then(() => {
				dispatch(changeView(view));
			});
		},
		onSetNotificationTab:(tab) => {
			dispatch(setNotificationTab(tab))
		},
		openAnnouncement: (id) => {
			dispatch(fetchAnnouncement({id}));
			dispatch(toggleShowAnnouncement(true));
		}
	};
};

export const IconizeSideBarMenuCtnr = connect(mapIconizeSideBarMenu,
	mapIconizeSideBarMenuCallbacks)(IconizeSideBarMenu);

const mapWFInnerContainer = (state, props) => {
	let wf = state.app.workflow
		, s = {
			windowInnerHeight: wf.ui.windowInnerHeight
		};
	return s;
};

export const WorkflowInnerContainerCtnr = connect(mapWFInnerContainer)(WorkflowInnerContainer);

const mapBreadcrumbsStateToProps = (state, props) => {
	const wf = state.app.workflow
		, header = state.app.header
		, menu = state.app.menu
		, search = state.app.search
		, erd = state.app.errand
		;
	return {
		activeMainMenu: menu.mainMenu.activeMainMenu
		, currentContext: contextMemo(state)
		, chatErrandCount: wf.errandListChat.length
		, errandCount: state.domain.counters
		, filter: wf.filter
		, globalSearchType: search.ui.globalSearch
		, myErrandFolder: myErrandFolderMemoize(state)
		, totalAllErrandResults: search.results.totalAllErrand
		, totalAreas: search.results.totalAreas
		, totalCloseErrandResults: search.results.totalClosedErrand
		, totalContacts: search.results.totalContacts
		, totalCustomerNotes : search.results.totalCustomerNotes
		, totalErrand: search.results.totalErrand
		, totalErrandNotes: search.results.totalErrandNotes
		, totalErrandsResults: search.results.totalErrand
		, totalExternalExpertContacts: search.results.totalExternalExpertContacts
		, totalKBs: search.results.totalKBs
		, totalMyErrandResults: search.results.totalMyErrand
		, totalTags: search.results.totalTags
		, userFolders: wf.fetchWfSettings.data.userFolders
		, reviewEnabled: reviewFolderEnabledMemo(state)
		, postponeEnabled: postponeFolderEnabledMemo(state)
		, vipCount: getVIPListCounters(state)
		, vipTags: vipTagsSelector(state)
		, wfSettings: wf.fetchWfSettings
		, gridFolderIndex: erd.ui.gridFolderIndex
	};
};

const mapBreadcrumbsCallbacks = (dispatch, props) => ({
		onClickPath: (path, folderId) => {
			if(path == MY_ERRANDS) {
				let params = {};
				params.classification = "";
				params.folder = folderId;
				params.source = MY_ERRANDS;
				dispatch(changeContext(MY_ERRANDS, params));
			}
		}
		, onFolderClick: (toContext, params, fromContext) => {
			new Promise(resolve => {
				let p;
				if (fromContext === CTX_REVIEW) {
					p = dispatch(pushV5(toContext, params));
				}
				if (fromContext === CTX_POSTPONED) {
					p = dispatch(pushV5(toContext, params));
				}
				resolve(p);
			})
			.then(() => dispatch(contextChangeByFolder(toContext, params)))
			;
		}
		, onReviewClick: () => dispatch(cancellableReviewClick())
		, onSetGridFolderIndex: (p) =>{
			dispatch(setGridFolderIndex(p))
		}
});

export const WorkflowBreadcrumbsCtnr = connect(mapBreadcrumbsStateToProps,
	mapBreadcrumbsCallbacks)(WorkflowBreadcrumbs);

const mapSortFilterStates = (state, props) => {
	const wf = state.app.workflow;
	return {
		agentAreas: contextAwareActiveAreasMemo(state)
		, agentFilter: agentFilterSelector(state, props)
		, agentInboxSize: wf.fetchWfSettings.data.agentInboxSize
		, agents: onlyActiveAgentsSelector(state, props)
		, areaFilter: areaFilterSelector(state, props)
		, context: currentContextFromInputList(state)
		, currentContext: contextMemo(state)
		, filter: wf.filter
		, getCurrentAreas: getCurrentAreasSelector(state, props)
		, isGrpLeader: wf.fetchWfSettings.data["group-leader.personal-errands"]
		, listInputs: wf.listInputs
		, myErrandFolder: myErrandFolderMemoize(state)
		, orgAreas: contextAwareOrgAreasMemo(state)
		, selectedAreas: selectedFilterAreasMemoize(state)
		, selectedOrgs: selectedFilterOrgsMemoize(state)
		, sort: wf.sort
		, tagsFilter: tagsFilterSelector(state, props)
		, ui: wf.ui
	};
};

const mapSortFilterCallbacks = (dispatch, props) => ({
	onChangeAreaFilter: (context, areasObject) => {
		dispatch(setListParams({offset:0})) //reset back to first page
		dispatch(changeAreaFilter(context, areasObject));
	}
	, onChangePriorityFilter: payload => {
		dispatch(updatePrioritySort(payload));
	}
	, onSetFilteredAreas: areas => {
		dispatch(setFilteredAreas(areas));
	}
	, onSetFilteredTags: (tags) => {
		dispatch(setFilteredTags(tags));
	}
	, onSetParamsThenReload: (p) => {
		dispatch(changeParamsThenReload(p));
	}
	, onSetSelectedAgent: (id) => {
		dispatch(setSelectedAgent(id));
	}
	, onSetSelectedFolder: (id) => {
		dispatch(setSelectedFolder(id));
	}
	, onSetSelectedTags: (ids, add) => {
		dispatch(setSelectedTags(ids, add));
	}
	, onShowAllArea: () => {
		dispatch(showAllArea());
	}
	, onToggleAgentsFilter: (toggleState) => {
		dispatch(selectToggleAgentsFilter(toggleState));
	}
	, onToggleAreasListFilter: () => {
		dispatch(selectToggleAreasFilter());
	}
	, onToggleSideBar: (toggle) => {
		dispatch(selectToggleSideBar(toggle));
	}
	, onToggleTagsFilter: (toggleState) => {
		dispatch(selectToggleTagsFilter(toggleState));
	}
	, setLoadList: () => {
		dispatch(loadList("sortFilter"));
	}
});

export const TopWorkflowFilterCtnr = connect(
	mapSortFilterStates
	, mapSortFilterCallbacks
)(TopWorkflowFilter);

const mapStateToProp = (st, props) => {
	const wf = st.app.workflow
		, erd = st.app.errand
		, d = st.domain
		, search = st.app.search
		, areaData = getCurrentErrandAreaData(st, props)
		, selectedCount = getSelectedSearchErrandCount(st)
		, currentContext = contextMemo(st)
		;
	return {
		 activateChat: st.app.footer.uiData.activateChatWindow
		, adminTag: wf.adminTagSimpleList
		, areaData: wf.areaData
		, areaErrandCount: wf.areaErrandCount
		, areas: allAreasSelector(st, props)
		, canOffCollaborationLight: getAllowLightBulbOff(st)
		, channels: serverChannelsSelector(st, props)
		, chatErrandSelected: getChatErrandSelected(st)
		, chatErrandCount: wf.errandListChat.length
		, clientAvatar: st.domain.clientAvatar
		, closeErrand: wf.closeErrand // TODO: seem useless and should be removed
		, collaborationInfo: getCollaborationInfoSelector(st, props)
		, currentContext: contextMemo(st)
		, currentOpened: getCurrentOpenErrandSelector(st, props)
		, defaultFolderId: myDefaultFolderMemoize(st)
		, errandAllSelected: getErrandAllSelected(st)
		, errandCount: st.domain.counters
		, errandList: wf.errandList
		, errandListChat: wf.errandListChat
		, errandPreview: d.previewData.byId[wf.ui.currentPreviewId] ? d.previewData.byId[wf.ui.currentPreviewId] : ""
		, forwardErrandsAgents: forwardErrandsAgentsSelector(st)
		, forwardToAgent: areaData.agents
		, forwardToArea: areaData.forward_to_areas
		, fullTags: wf.adminTagList.data
		, hasClosedErrand: selectedCount.closedErrands > 0
		, hasDueDateSelected: getHasDueDateSelected(st)
		// TODO: lazy solution but what if search list can find these errand?
		, hasPendingReviewErrand: currentContext === CTX_REVIEW
		, isLoadingFwdErrandsAgents: isLoadingFwdErrandsAgentsSelector(st)
		, isSearch:  isFromSearch(st)
		, loadingErrandList: wf.errandList.wip
		, myErrandsOnly: (IsContextSearch(currentContext) && selectedCount.allErrands == 0 && selectedCount.myErrands != 0 && selectedCount.closedErrands == 0) ? true : false
		, previewActivated: getPreviewFeatures(wf.fetchWfSettings.data ? wf.fetchWfSettings.data : {}, currentContext)
		, printContent: erd.printContent.data
		, queueToMe: wf.queueToMe
		, realTimeCounter: true
		, removeTempAttachment: wf.removeTempAttachment
		, reviewFolderEnabled: reviewFolderEnabledMemo(st)
		, review: reviewSelector(st)
		, postponeFolderEnabled: postponeFolderEnabledMemo(st)
		, postponed: postponedSelector(st)
		, returnToInbox: wf.returnToInbox
		, searchList: searchListSelector(st, props)
		, selectedErrands: actionsSelectedErrandsSelector(st)
		, shouldPopPrint: erd.ui.shouldPopPrint
		, showMultipleActions: wf.ui.showMultipleActions
		, searchFilterBy: search.ui.globalSearch.filterBy
		, searchOffset: search.ui.bodySearch.topResults
		, searchResults: search.results
		, slaTime: getSlaTimeSelector(st, props)
		, totalSearch: search.results.totalErrand
		, totalSearchFiltered: totalSearchResult(st)
		, totalSelected: getTotalSelectedErrands(st)
		, uploadAnswerAttachment: wf.uploadAnswerAttachment
		, wfSettings: wf.fetchWfSettings
		, workflowReady: wf.globalReady
		, agentWorking: getAgentWorking(st)
		, vipTags: vipTagsSelector(st)
		, vipTagsErrandsCount: getVIPListCounters(st)
		, windowInnerHeight: wf.ui.windowInnerHeight
		, videoCallFullScreen: erd.chat ? erd.chat.videoCallFullScreen : false
		, uiData: st.app.header.uiData
		, agentTimezoneOffset: agentTimezoneOffsetSelector(st)
		, menu: st.server.menu.rightMenu
		, agent: agentNameSelector(st)
		, newIMCount: st.app.internalMessage.counters.newIM.count
		, notification: st.app.notification
		, isSearching: search.inputs.searchOngoing
		, searchResults: search.results
		, searchText: search.inputs.headerText
		, showResultBox: search.inputs.showResultBox
		, disableActiveSearch: wf.fetchWfSettings.data.disableActiveSearch
		, callEnabled: isTwilioCallEnabled(st)
		, callPadPopupShown: isCallPadPopupShown(st)
		, callStatus: callStatusMemoize(st)
		, agentStatus: agentStatusSelector(st)
		, agentStatusById: getAgentStatusById(st)
		, agentStatusByName: getAgentStatusByName(st)
		, agentStatusList: getAgentStatusData(st)
		, activeCreatedErrand: getCallCreatedId(st)
		, sipGetCurrentEid: sipGetCurrentEid(st)
		, listViewData: getListViewData(st)
		, sipMakeCallCurrentErrand: sipMakeCallCurrentErrand(st)
		, previousErrandId: getPreviousErrandId(st)
		, gridFolderIndex: erd.ui.gridFolderIndex
	};
};

const mapWfCallbacks = (dispatch, props) => ({
	fetchAreaErrandCount: () => {
		return dispatch(fetchAreaErrandCount());
	}
	, fetchErrandList: (ctx, param) => {
		return dispatch(errandList(ctx, param));
	}
	, fetchTags: () => {
		return dispatch(onceAdminTagSimpleList());
	}
	, handleSearchList: (opt) =>{
		let filterBy = FILTER_SEARCH_ERRAND_EMPTY;
		switch (opt) {
			case SEARCH_MY_ERRANDS:
			filterBy = FILTER_SEARCH_ERRAND_MY;
			break;
			case SEARCH_NEW_ERRANDS:
			filterBy = FILTER_SEARCH_ERRAND_ALL;
			break;
			case SEARCH_CLOSE_ERRANDS:
			filterBy = FILTER_SEARCH_ERRAND_CLOSE;
			break;
		}
		dispatch(handleFilterByLabel(filterBy));
		dispatch(handleResetOffset());
		dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
		dispatch(changeContext(opt));
	}
	, loadGlobalSearch:(offset) =>{
		dispatch(handlePagination(offset, SEARCH_ERRAND));
		dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
	}
	, onBackToListView: () => {
		dispatch(backToListView());
	}
	, onChangeAdminView: (view, url) => {
		dispatch(push(url)).then(() => dispatch(changeAdminView(view)));
	}
	, onClickReview: (id, cipherKey) => dispatch(pushReviewErrandURL(id, cipherKey))
	, onCloseErrands: (ids) => {
		dispatch(fetchAgentWorkingOnSelectedErrands())
		.then((data) => {
			if(!data.agent_working_on_errand) {
				dispatch(doCloseErrand(ids, true));
			}
		});
	}
	, onCollapseSideBar: (toggle) => {
		dispatch(selectCollapseSideBar(toggle));
	}
	, onDeleteErrands: (ids) => {
		dispatch(fetchAgentWorkingOnSelectedErrands())
		.then((data) => {
			if(!data.agent_working_on_errand) {
				dispatch(doDeleteErrand(ids, true));
			}
		});
	}
	, onExitFromReview: () => dispatch(exitReview(true))
	, onExitFromPostpone: () => dispatch(exitPostpone(true))
	, onFolderClick: (toContext, params, fromContext) => {
		if (process.env.NODE_ENV !== 'production') {
			console.log('onFolderClick', toContext, params, fromContext);
		}
		new Promise(resolve => {
			let p;
			if (fromContext === CTX_REVIEW) {
				p = dispatch(pushV5(toContext, params));
			}
			if (fromContext === CTX_POSTPONED) {
				p = dispatch(pushV5(toContext, params));
			}
			resolve(p);
		})
		.then(() => dispatch(contextChangeByFolder(toContext, params)))
		;
	}
	, onHandleForwardToArea: (list, cipher_keys, areaID) => {
		dispatch(fetchAgentWorkingOnSelectedErrands())
		.then((data) => {
			if(!data.agent_working_on_errand) {
				dispatch(forwardErrandsToArea(list, cipher_keys, areaID, true));
			}
		});
	}
	, onHandleMoveToFolder: ({ list, cipher_keys, folder }) => {
		if (list) {
			dispatch(fetchAgentWorkingOnSelectedErrands())
			.then((data) => {
				if(!data.agent_working_on_errand) {
					dispatch(moveToFolder(list, cipher_keys, folder));
				}
			});
		}
	}
	, onHandleMultipleErrandAction: (actionFor, show) => {
		dispatch(controlMultipleActions(actionFor, show));
	}
	, onHandlePrintErrands: (eids, doPrint) => {
		dispatch(fetchPrintErrand(eids, doPrint));
	}
	, onHandleReturnToInbox: (ids) => {
		dispatch(fetchAgentWorkingOnSelectedErrands())
		.then((data) => {
			if(!data.agent_working_on_errand) {
				dispatch(putBackToInbox(ids, true));
			}
		});
	}
	, onHandleSendingBulkErrand: (ids) => {
		dispatch(fetchAgentWorkingOnSelectedErrands())
		.then((data) => {
			if(!data.agent_working_on_errand) {
				dispatch(selectManualErrand(ME_START));
				dispatch(selectManualReply(ME_START));
				dispatch(toggleWorkflowPopup(WFP_BULK_SEND_ERRAND, true));
			}
		});
	}
	, onHandleLinkErrand: (ids) => {
		dispatch(fetchAgentWorkingOnSelectedErrands())
		.then((data) => {
			if(!data.agent_working_on_errand) {
				dispatch(linkErrand(ids));
			}
		});
	}
	, onHandleSetDueDate: (ids, cipherKeys, duedate, errandId) => {
		dispatch(setErrandsDueDate({list: ids, cipher_keys: cipherKeys, done_date: duedate},
			errandId));
		}
	, onHandleSetPriority: (ids, cipherKeys) => {
		dispatch(setErrandsPriority({list: ids, cipher_keys: cipherKeys, highPriority: true}, true));
	}
	, onGetCollaborationInfo: (id) => {
		dispatch(fetchWorkflowExpertQueries(id));
	}
	, onGetSlaTime: (id) => {
		dispatch(fetchSlaTime(id));
	}
	, simpleLoadAndOpen: (encoded, eid) => {
		dispatch(decryptAndLoadErrand(encoded, eid));
	}
	, onLoad: (p) => {
		let openErrandID
			, chat = null
			;
		if (props.isExternal) {
			if (typeof externalqueue !== "undefined"
				&& externalqueue.isChat == true) {
				for (let i=0; i < p.errandListChat.length; i ++) {
					if (p.errandListChat[i].sessionId == externalqueue.sid) {
						chat=p.errandListChat[i];
						openErrandID=chat.errand.data.id;
						break;
					}
				}
			} else {
				openErrandID = parseInt(externalqueue.errandId,10);
			}
		}
		if (props.loadErrandId) {
			openErrandID = parseInt(props.loadErrandId, 10);
		}
		if (props.searchCtx) {
			switch(p.searchFilterBy) {
				case FILTER_SEARCH_ERRAND_MY:
					dispatch(changeContext(SEARCH_MY_ERRANDS));
					break;
				case FILTER_SEARCH_ERRAND_ALL:
					dispatch(changeContext(SEARCH_NEW_ERRANDS));
					break;
				case FILTER_SEARCH_ERRAND_CLOSE:
					dispatch(changeContext(SEARCH_CLOSE_ERRANDS));
					break;
				default:
					dispatch(changeContext(SEARCH_ERRANDS));
			}
		} else {
			if (props.errandCtx) {
				dispatch(changeContext(MY_ERRANDS));
			} else {
				// TODO: this logic that depend on errand list chat length
				// is NOT right because this part likely always trigger
				// first before websocket (errand list chat should carry by
				// websocket?) and errand list chat will always be zero at
				// the moment this on load trigger.
				if (!p.wfSettings.data["hide-new-errands-menu"]
					&& myErrandCount.total === 0
					&& p.errandListChat.length === 0) {
					dispatch(changeContext(NEW_ERRANDS));
				} else {
					dispatch(changeContext(MY_ERRANDS));
				}
			}
		}
		if(getExtQueueType() !== ""){
			dispatch(onceConnectedAgentAreas());
			return dispatch(workflowAddAgentData(activeUserId,
				openErrandID, props.loadErrandHash, chat))
				.then((ok) =>{
					if(ok){
						dispatch(setWorkflowReady());
					} else {
						dispatch(workflowOnLoad(openErrandID,
							props.loadErrandHash, chat)).then(() => {
							dispatch(setWorkflowReady());
						});
					}
				});
		} else {
			return dispatch(workflowOnLoad(openErrandID,
				props.loadErrandHash, chat)).then(() => {
				dispatch(setWorkflowReady());
				dispatch(sendSessionReady());
			});
		}
	}
	, onLoadPreview: (id) => {
		if (id > 0) {
			dispatch(errandPreview(id));
		}
	}
	, onOpenForwardToAgent: areaIds => {
		dispatch(fetchAreaForwardAgents(areaIds));
	}
	, onOpenReviewErrand: (id, cipherKey) => {
		dispatch(openReviewErrand(id, cipherKey));
	}
	, onReviewClick: () => dispatch(cancellableReviewClick())
	, onSetGridFolderIndex: (p) =>{
		 dispatch(setGridFolderIndex(p))
	}
	, onOpenReviewFolder: () => dispatch(openReviewFolder())
	, onOpenPostponeFolder: () => dispatch(openPostponeFolder())
	, onSelectErrandListSort: (value, index) => {
		dispatch(selectOrToggleSort(value, index));
	}
	, onSelectForwardToAgent: (agentId, errandIds, cipherKeys) => {
		dispatch(fetchAgentWorkingOnSelectedErrands())
		.then((data) => {
			if(!data.agent_working_on_errand) {
				dispatch(forwardMultipleErrandsToAgent(
					agentId
					, errandIds
					, cipherKeys
				));
			}
		});
	}
	, onSelectErrandFromList: (id, select) => {
		dispatch(selectErrandFromList(id, select));
	}
	, onSelectAllErrands: (ctx, toggleState) => {
		dispatch(selectAllErrands(ctx, toggleState));
	}
	, onSetActivateErrandMobileView: (toggle) => {
		dispatch(setErrandMobileView(toggle));
	}
	, onSetCurrentErrand: (id, chat, extra, search, prevErdId) => {
		dispatch(setPreviousErrand(0));
		dispatch(openErrandFromList(id, chat, extra, search));
	}
	, onSetErrandView: listView => {
		dispatch(agentSetErrandView({preferredErrandsView: listView}));
	}
	, onSetMobileView: (toggle, winHeight) => {
		dispatch(setMobileView(toggle, winHeight));
	}
	, onToggleErrandListSort: toggleState => {
		dispatch(selectOrToggleSort(toggleState));
	}
	, onCloseErrandChatSidePanel: toggleState => {
		dispatch(toggleErrandChatSidePanel(toggleState));
		dispatch(resetAgentSatisfaction());
	}
	, onToggleLight: errandId => {
		dispatch(toggleCollaborationLight(errandId));
	}
	, onTogglePriorityFilter: (toggleState) => {
		dispatch(selectTogglePriorityFilter(toggleState));
	}
	, setParams: (p) => {
		dispatch(setListParams(p));
	}
	, handleAcquireFromOtherAgentErrand: (...args) => {
		dispatch(acquireFromOtherAgentErrand(...args));
	}
	, onToggleArrow: (key) => {
		dispatch(toggleHeaderArrow(key));
	}
	, onShowAllNotification: () => {
		dispatch(toggleShowAllNotification(true));
		dispatch(toggleShowAnnouncement(false));
	}
	, OpenLastErrandInThread: (id) => {
		dispatch(findAndOpenLastErrandInThread(id));
		dispatch(handleHeaderSearchHide());
	}
	, openCollaReplyPanel: (eId, tId, qId, isReply) => {
			dispatch(loadAndOpenErrand(eId))
			.then(() => {
				dispatch(expandHideOption('collaboration', eId, true));
				dispatch(multiDispatchesEE(eId))
				.then(() => {
					dispatch(fetchEEThread(tId, eId)).then(() => {
						dispatch(expandCollabQueryThread(tId, true));
						if(!isReply){
							dispatch(setupCollaborationQuery(tId, qId));
						} else {
							dispatch(selectShowReply(RPLY_COLLABORATE,true));
						}
					});
				})

			});
		dispatch(handleHeaderSearchHide());
	}
	, openErrand: (id) => {
		dispatch(loadAndOpenErrand(id));
		dispatch(handleHeaderSearchHide());
	}
	, openMessage: (id) => {
		dispatch(openSingleIM(id));
		dispatch(handleHeaderSearchHide());
	},
	openStatistics: id => {
		dispatch(statisticsOnLoad())
		.then(r => {
			dispatch(loadSavedReport(id));
		});
	}
	, openAccounts: (id, link) => {
		let view = ACCOUNTS_VIEW_SLICE[id];
		dispatch(push(link)).then(() => dispatch(changeAdminView(view)));
	}
	, handleKeyUpSearchBox: (e, setHeaderDropdown)=>{
		if (typingTimer) clearTimeout(typingTimer);
		if(e.target.value !== ""){
			dispatch(handleSearchSpinner(true));
			typingTimer = setTimeout(()=>{
				dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_HEADER,
					setHeaderDropdown));
			}, doneTypingInterval);
		}
	}
	, handleKeyDownSearchBox: (e) =>{
		clearTimeout(typingTimer);
		if(e.keyCode === 8 || e.keyCode === 46){ //backspace, delete
			dispatch(handleSearchTextChange('header', e.target.value));
		}else if(e.keyCode === 27){ //esc
			dispatch(handleSearchTextChange('header', ""));
			dispatch(handleHeaderSearchHide());
		}
	}
	, handleChangeSearchBox: (e) =>{
		e.preventDefault();
		let val = e.target.value;
		dispatch(handleSearchTextChange('header',val));
	}
	, handleSearchButton: (e, val) =>{
		console.log('button',e);
		dispatch(handleSearchSpinner(true));
		dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_HEADER, true));
	}
	, handleHeaderSearchDD: (val) =>{
		dispatch(handleHeaderSearchHide(val));
	}
	, onLoadCall: ws => dispatch(checkBrowser(ws)).then(audioInputListS => {
		if (audioInputListS) {
			dispatch(onceOutboundPhones());
		}
		return audioInputListS;
	})
	, onClickCallIcon: showState => {
		dispatch(toggleCallPadPopup(showState));
	}
	, onPopAlert: (...args) => dispatch(popErrorOnly(...args))
	, onPopupAudioInputSelection: (...args) => dispatch(optionalConfirm(...args))
	, onSelectAudioInput: selected => { dispatch(updateAudioInput(selected)) }
	, onUnload: () => {
		console.log("dbg: header can not unmount at v5 page. check ws.");
		dispatch(stopNewIMCounters());
		dispatch(stopPeriodicLoadList());
		dispatch(stopPeriodicAgentStatus());
		dispatch(stopPeriodicAutomaticLogout())
	}
	, onUpdateStatus: (updateStatus) => {
		dispatch(updateAgentStatus(updateStatus));
	}
	, onSetPostMessageErrandCount: (toggle) => {
		dispatch(setPostMsgSubscribeErrandCount(toggle));
	}
	, onStatusLoad: (isAventa) => {
		dispatch(agentStatusOnLoad())
		.then(data =>{
			if(isAventa){
				if(data != "loggedout"){
					dispatch(toggleChatSource(
							Workflow.Errand.SERVICE_AVENTA, true));
				}
			}
		});
		if(autoLogout != undefined && autoLogout.enabled ){
			dispatch(agentPing())
			.then(() => {
				dispatch(startPeriodicAutomaticLogout())
			})
		}
	},
	onMainView: () => {
		dispatch(resetWorkflowView());
	},
	handleIntegrationCreateManualErrand: p => {
		return dispatch(integrationCreateManualErrand(p))
	},
	onSetNotificationTab:(tab) => {
		dispatch(setNotificationTab(tab))
	},
	openAnnouncement: (id) => {
		dispatch(fetchAnnouncement({id}));
		dispatch(toggleShowAnnouncement(true));
	},
	onFetchQueueErrands(msg) {
		dispatch(fetchQueueErrands(msg));
	}
});

const createConnectMap = (...maps) => (...args) => {
	let callbacks = emptyObject;
	maps.forEach(v => {
		callbacks = update(callbacks, {$merge: v(...args)});
	});
	return callbacks;
};

const WorkflowCtnr = connect(
	createConnectMap(
		mapSortFilterStates
		, mapStateToProp
	)
	, createConnectMap(
		mapSortFilterCallbacks
		, mapWfCallbacks
	)
)(WorkflowNew);

export default WorkflowCtnr;
