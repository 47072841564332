import React, { useReducer, useState, memo, useMemo, useEffect } from "react";
import classNames from 'classnames'
import { TableIconicButton as Button, TextInputRow } from '../../../reactcomponents/Form';
import styled from "styled-components";
import {
    composeWithDisplayName,
    createWithMountCondition,
    omitProps,
    withUnmountWhenHidden
} from '../../../reactcomponents/hocs'
import { I, webRoot } from '../../../common/v5/config';
import { SYSTEM_REPORTS, CUSTOM_REPORTS } from "../../../common/v5/constants";

const NavigationMenu = ({
    list,
    className,
    selected,
    onSelect,
    menuName,
    idField: id,
    nameField: name,
    labelField: label,
    isCustomSelected,
    hidden,
    title
}) => {

    const [filter, setFilter] = useState('');
    const filteredItems = list.filter(item => item[label].toLowerCase().includes(filter.toLowerCase()));

    const handleSearchFilter = (event) => {
        setFilter(event.target.value);
    }

    return (
        <div hidden={hidden} className={`sidepanel ${className ? className : ''}`}>
            <div className="sidepanel__header">
                <h3 className="header-text">{title}</h3>
                <div className="search-input-wrapper">
                    <input
                        className="standard-textfield"
                        type="search"
                        value={filter}
                        placeholder={I("Search reports")}
                        onChange={handleSearchFilter}
                    />
                    <Button
                        type='button'
                        className={"btn bg-transparent clear-btn"}
                        iconClass='icon-times'
                        title={I('Clear')}
                        onClick={e => setFilter('')}
                        hide={!filter}
                        // active={showInline}
                    />
                </div>
            </div>
            <div className="sidepanel__body">
                <ul className='menu__container'>
                    {filteredItems.map(item => (
                        <li
                            key={menuName + item[id]}
                            data-qa-id={menuName + item[name]}
                            id={item[id]}
                            className={classNames("menu__item", { "active": (item[id] === selected) })}
                            onClick={(e) => onSelect(item[id], isCustomSelected)}
                        >
                            <a>{item[label]}</a>
                        </li>
                    ))
                    }
                </ul>
            </div>
        </div>
    )
}

export const StatisticsSidePanel = ({
    list
    , children
    , selected
    , onSelectReport
    , title
    , hidden
    , isCustomSelected
}) => {

    return (
        <div className="app-body statistic">
            <NavigationMenu
                className={"statistics"}
                list={list}
                selected={selected}
                onSelect={onSelectReport}
                title={title}
                menuName={"view_reports_nav_"}
                idField={"Id"}
                nameField={"Name"}
                labelField={"i18nName"}
                hidden={hidden}
                isCustomSelected={isCustomSelected}
            />
            {children}
        </div>
    )
}

