import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from '../../common/v5/utils';
import { V5 } from '../../common/path';
import Statistics from './statistics';
import {
	changeAdminView,
	adminActionAlert,
	toggleAdminEdit
} from '../../redux/actions/admin';
import {
	selectToggleSideBar,
	selectCollapseSideBar,
	setMobileView,
	resetWorkflowView,
	setListParams
} from '../../redux/actions/workflow';
import {
	togglePopAlert
} from '../../redux/actions/hmf';
import {
	showContactBook,
	setReplyAddressType
} from '../../redux/actions/errand';
import {
	changeView,
	updateChartParameter,
	toggleSchedule,
	updateScheduleReport,
	resetScheduleReport,
	toggleShare,
	updateShareParam,
	openScheduleReport,
	clearShareReport,
	saveShareReport,
	toggleCreateReport,
	updateCreateReport,
	toggleReportPreview,
	toggleConfigTime,
	updateConfigTime,
	reOrderConfigTime,
	removeConfigTimeSelection,
	reOrderCreateReportKey,
	reOrderCreateReportGroup,
	removeSelectedKey,
	removeSelectedGroup,
	resetConfigTime,
	clearSelectionConfigTime
} from '../../redux/actions/statistics';
import {
	drilldownReport
	, exportReport
	, linkToReportPage
	, reloadReportData
	, saveScheduleReport
	, statisticsOnLoad
	, switchReportPage
	, showScheduledReports
	, updateOverviewGridOrChart
	, removeScheduleReport
	, shareReport
	, loadCustomReports
	, openCustomReport
	, saveCustomReport
	, removeCustomReport
	, generateReportPreview
	, openConfigTime
	, removeTimeConfig
	, saveConfigTime
	, saveOverviewGrid
	, getLiveReportQueueChat
	, getAgentWithChatStatus
} from '../../redux/actions/async/statistics';
import {
	onceStatisticsAgentAreas
	, fetchAllActiveErrands
	, fetchAllIncomingEmailErrands
	, fetchAllActiveAgents
	, decryptAndLoadErrand
} from '../../redux/actions/async/workflow';
import {
	sipGetAllAgentList
	, sipGetActiveCalls
	, sipGetSipNumberInUse
} from '../../redux/actions/async/call';
import {
	sipStartSnoop
} from '../../redux/actions/async/sippRtc';
import {
	agentTimezoneOffsetSelector
} from '../../redux/selectors/errand';
import {
	activeViewMemo
	, activeViewReportExportParameterSelector
	, headerTitleSelector
	, isLoadingReportDataSelector
	, getStatisticsRoot
	, overviewChartMapMemo
	, overviewGridMemo
	, saveDisabledGridMemo
	, overviewWidgetListSelector
	, overviewMidPanelListSelector
	, overviewRightPanelListSelector
	, reportChartLayout
	, reportIdSelector
	, reportOptionsSelector
	, reportParametersSelector
	, reportCompareParametersSelector
	, reportSourceTypeSelector
	, timeZoneListSelector
	, reportAreasSelector
	, timeSpanFormatListSelector
	, groupListSelector
	, getPinReportsById
	, getSelectedKeysName
	, getSelectedGroupsName
	, getSortedKeyNamePair
	, getSortedGroupNamePair
	, getSystemReportsByName
	, getReportKeysCommonDependencies
	, getReportsById
	, timeSpanItemsListSelector
	, getSelectedTimeSpanInfo
	, getSelectedAgents
	, getSelectedTimeSpanExample
	, getStartDay
	, liveReportQueueData
	, liveReportInConversationData
	, liveReportAgentsQueueData
	, liveReportGroupQueueData
	, liveReportSIPNumbInUsed
	, sortedStatisticsReportSelector
} from '../../redux/selectors/statistics';
import {
	RL_LEFT_PANE,
	RL_RIGHT_PANE,
	RL_BOTTOM_PANE,
	RL_SUB_COMPARE_PANE,
	MESSAGE_INVALID_EMAIL,
	LIVE_REPORT_EMAIL,
	LIVE_REPORT_CHAT,
	LIVE_REPORT_VOICE,
	LIVE_REPORT_OVERVIEW,
	NEW_ERRANDS
} from '../../common/v5/constants';
import {
	onlyActiveAgentsSelector
	, serverChannelsSelector
	, filterAreaSelector
} from '../../redux/selectors/workflow';
import ExecutiveReport from '../../components/v5/ExecutiveReport';
import {default as Report, CompareStat} from '../../components/v5/Report';
import OrganizationReport from '../../components/v5/OrganizationReport';
import SatisfactionMeterReport from '../../components/v5/SatisfactionMeterReport';
import ErrandsReport from '../../components/v5/ErrandsReport';
import { default as ScheduleReport, ScheduledReports, EditFormWithPopup } from '../../components/v5/ScheduleReport';
import ExportReport from '../../components/v5/ExportReport';
import ShareReport from '../../components/v5/ShareReport';
import { default as CreateReport } from '../../components/v5/CreateReport';
import {default as ConfigureTime, ConfigureTimeFormOld,} from '../../components/v5/ConfigureTime';
import LiveReport from '../../components/v5/LiveReport';

const mapStatistics = (state, props) => {
	const wf = state.app.workflow
		, { statistics } = state.app
		, app = state.app
		, admin = app.admin
		, adminSt = admin.admin
		;
	return {
		ui: wf.ui
		, exportParameter: activeViewReportExportParameterSelector(state, props)
		, activeView: statistics.view.active
		, activeId: statistics.view.activeId
		, reports: statistics.reports.data
		, reportGroup: sortedStatisticsReportSelector(state)
		, reportsById: statistics.reports.byId
		, title: headerTitleSelector(state, props)
		, ready: statistics.view.ready
		, activateChat: state.app.footer.uiData.activateChatWindow
		, reportByName: getSystemReportsByName(state)
		, adminAlert: adminSt.adminAlert,
	};
};

var typingTimer;
var doneTypingInterval = 500;

const mapStatisticsCallbacks = (dispatch, props) => {
	return {
		onExport: (reportId, parameter) => {
			dispatch(exportReport(reportId, parameter));
		},
		onLoad: () => {
			dispatch(onceStatisticsAgentAreas()),
			dispatch(statisticsOnLoad());
		},
		onToggleSideBar: toggle => {
			dispatch(selectToggleSideBar(toggle));
		},
		onCollapseSideBar: (toggle) => {
			dispatch(selectCollapseSideBar(toggle));
		},
		onChangeView: (view) => {
			dispatch(changeView(view));
			dispatch(toggleAdminEdit(0, false, false));
			dispatch(adminActionAlert({ show: false, msg: "", type: ""}));
		},
		onSwitchReport: (view, report, data) => {
			dispatch(adminActionAlert({ show: false, msg: "", type: ""}));
			return dispatch(switchReportPage(view, report, data));
		},
		showSchedule: toggle => {
			// create schedule report
			dispatch(toggleSchedule(toggle));
			dispatch(openScheduleReport(0));
		},
		showShareReport: toggle => {
			dispatch(toggleShare(toggle));
		},
		showCreateReport: (toggle) => {
			dispatch(toggleCreateReport(toggle));
			// TODO: this part need to optimize because everytime it is triggered it
			// request backed for reportkeys and report groups, what's the point of
			// SPA? Quick one just use 'once' async.
			dispatch(openCustomReport());
		},
		showCreateConfigTime: (toggle) => {
			dispatch(resetConfigTime());
			dispatch(openConfigTime());
		},
		onSetMobileView: (toggle) => {
			dispatch(setMobileView(toggle));
		},
		onChangeAdminView: (view, url) => {
			dispatch(push(url)).then(() => dispatch(changeAdminView(view)));
		},
		onMainView: () => {
			dispatch(resetWorkflowView());
		},
		onDismissAlert: () => {
			dispatch(adminActionAlert({ show: false, msg: "", type: ""}));
		},
		simpleLoadAndOpen: (encoded, eid) => {
			dispatch(push(V5))
			.then(() => {
				dispatch(decryptAndLoadErrand(encoded, eid));
			});
		},
	};
};

export const StatisticsCtnr = connect(mapStatistics,
	mapStatisticsCallbacks)(Statistics);

const mapExecutiveReport = (state, props) => ({
	activeView: activeViewMemo(state)
	, agentTimezoneOffset: agentTimezoneOffsetSelector(state)
	, chartMap: overviewChartMapMemo(state)
	, grid: overviewGridMemo(state)
	, widgets: overviewWidgetListSelector(state, props)
	, middlePanels: overviewMidPanelListSelector(state, props)
	, pinReportsById: getPinReportsById(state)
	, rightPanels: overviewRightPanelListSelector(state, props)
	, reportsById: getReportsById(state)
	, systemReportsByName: getSystemReportsByName(state)
	, saveDisabled: saveDisabledGridMemo(state)
});

const mapExecutiveReportDispatch = (dispatch, props) => ({
	onGridChange: (grid, chartMap, removee) => {
		dispatch(updateOverviewGridOrChart(grid, chartMap, removee));
	}
	, onLinkClick: (id, layout, position, index) => {
		dispatch(linkToReportPage(id, layout, position, index));
	}
	, onLoad: () => {
		//dispatch action
	}
	, onGridSave: (grid, chartMap) => {
		dispatch(saveOverviewGrid(grid, chartMap));
	}
});

export const ExecutiveReportCtnr = connect(
	mapExecutiveReport
	, mapExecutiveReportDispatch
)(ExecutiveReport);

function mapReportParameter(state, props) {
	return {
		loading: isLoadingReportDataSelector(state, props)
		, options: reportOptionsSelector(state, props)
		, parameters: reportParametersSelector(state, props)
		, compareParameters: reportCompareParametersSelector(state, props)
		, reportId: reportIdSelector(state, props)
		, sourceType: reportSourceTypeSelector(state, props)
		, reportAreas: reportAreasSelector(state)
	};
}

const mapReport = (state, props) => {
	const layout = reportChartLayout(state);
	return {
		leftPanels: layout[RL_LEFT_PANE]
		, rightPanels: layout[RL_RIGHT_PANE]
		, bottomPanels: layout[RL_BOTTOM_PANE]
		, paramId: getStatisticsRoot(state).view.reportParamId
		, features: state.server.features
	};
};

const mapReportDispatch = (dispatch, props) => {
	return {
		onParametersChange: (paramId, field, value) => {
			dispatch(updateChartParameter(paramId, field, value));
		}
		, onReload: paramId => {
			dispatch(reloadReportData(paramId));
		}
		, onTableCellClick: (
			reportId
			, parameters
			, {groups, key}
			, parentTables
			, [
				event
				, isGroup
				, rowIndex
				, columnIndex
				, identifier
			]
			, ids
			, value
		) => {
			dispatch(drilldownReport(reportId, parameters, groups, key));
		}
	};
};

export const ReportCtnr = compose(
	connect(mapReport, mapReportDispatch)
	, connect(mapReportParameter)
)(Report);

const mapOrganizationReport = (state, props) => {
	const stat = state.app.statistics, s = {
		activeView: stat.view.active
	};
	return s;
};

const mapOrganizationReportDispatch = (dispatch, props) => {
	return {
		onLoad: () => {
			//dispatch action
		}
	};
};

export const OrganizationReportCtnr = connect(mapOrganizationReport, mapOrganizationReportDispatch)(OrganizationReport);

const mapSatisfactionMeterReport = (state, props) => {
	const stat = state.app.statistics, s = {
			activeView: stat.view.active
		};
	return s;
};

const mapSatisfactionMeterReportDispatch = (dispatch, props) => {
	return {
		onLoad: () => {
			//dispatch action
		}
	};
};

export const SatisfactionMeterReportCtnr = connect(mapSatisfactionMeterReport, mapSatisfactionMeterReportDispatch)(SatisfactionMeterReport);

const mapErrandsReport = (state, props) => {
	const stat = state.app.statistics, s = {
			activeView: stat.view.active
		};
	return s;
};

const mapErrandsReportDispatch = (dispatch, props) => {
	return {
		onLoad: () => {
			//dispatch action
		}
	};
};

export const ErrandsReportCtnr = connect(mapErrandsReport, mapErrandsReportDispatch)(ErrandsReport);

//For EditFormWithPopup and old popup form
const mapScheduleReport = (state, props) => {
	const stat = state.app.statistics, admin = state.app.admin, adminSt = admin.admin, s = {
		show: stat.view.schedule.toggle,
		activeView: stat.view.active,
		newReport: stat.scheduleReport.createNew,
		timeZones: timeZoneListSelector(state, props),
		timeStampFormat: timeSpanFormatListSelector(state, props),
		activeScheduled: stat.view.schedule.activeId,
		reportTypes: stat.reports.byId,
		otherOptions: reportOptionsSelector(state, props),
		channels: serverChannelsSelector(state, props),
		areas: filterAreaSelector(state, props),
		agents: onlyActiveAgentsSelector(state, props),
		groups: groupListSelector(state, props),
		features: state.server.features,
		activeId: stat.view.schedule.activeId,
		input: stat.scheduleReport.createNew,
		view: stat.view.scheduleReport,
		timeZones: timeZoneListSelector(state, props),
		timeStampFormat: timeSpanFormatListSelector(state, props),
		reportTypes: stat.reports.byId,
		otherOptions: reportOptionsSelector(state, props),
		channels: serverChannelsSelector(state, props),
		areas: filterAreaSelector(state, props),
		agents: onlyActiveAgentsSelector(state, props),
		groups: groupListSelector(state, props),
		saveStatus: adminSt.adminStatus
	};
	return s;
};

//For EditFormWithPopup and old popup form
const mapScheduleReportDispatch = (dispatch, props) => {
	return {
		showSchedule: toggle => {
			dispatch(toggleSchedule(toggle));
		},
		onSetScheduleReport: (field, value) => {
			dispatch(updateScheduleReport(field, value));
		},
		onSaveScheduleReport: () => {
			dispatch(saveScheduleReport());
		},
		onResetScheduleReport: () => {
			dispatch(resetScheduleReport());
		},
		onReloadList: () => {
			dispatch(showScheduledReports());
		},
		onFailValidation: (type, input) => {
			dispatch(togglePopAlert(MESSAGE_INVALID_EMAIL + ' ' + input));
		},
		onSave: () => {
			dispatch(saveScheduleReport());
			dispatch(toggleSchedule(false));
		},
		onCancel: () => {
			dispatch(toggleSchedule(false));
		},
		onClose: () => {
			dispatch(toggleSchedule(false));
		}
	};
};

export const ScheduleReportCtnr = connect(mapScheduleReport, mapScheduleReportDispatch)(ScheduleReport);

const mapShareReport = (state, props) => {
	const stat = state.app.statistics,
		share = stat.shareReport,
		s = {
			emailAddress: share.emailAddress,
			shareReportId: share.shareReportId,
			busy: share.busy,
			status: share.status,
			show: stat.view.share.toggle,
			selectedAgent: getSelectedAgents(state, props)
		};
	return s;
};

const mapShareReportDispatch = (dispatch, props) => {
	return {
		showShareReport: toggle => {
			dispatch(toggleShare(toggle));
		},
		onSetShareReport: (field, value) => {
			dispatch(updateShareParam(field, value));
		},
		onShareReport: () => {
			dispatch(shareReport());
		},
		resetShareReport: () => {
			dispatch(clearShareReport());
		},
		onToggleAgentBook: (tgl, type, context) => {
			dispatch(showContactBook(tgl));
			dispatch(setReplyAddressType(type, context));
		},
		onFailValidation: (type, inpt) => {
			dispatch(togglePopAlert(MESSAGE_INVALID_EMAIL + ' ' +inpt));
		}
	}
};

export const ShareReportCtnr = connect(mapShareReport, mapShareReportDispatch)(ShareReport);

const mapCreateReports = (state, props) => {
	const app = state.app
		, admin = app.admin
		, adminSt = admin.admin
		, stat = state.app.statistics;
	return {
		list: stat.customReports.data ? stat.customReports.data : [],
		showInline: adminSt.showInline,
		show: stat.view.createReport.toggle,
		activeId: stat.view.createReport.activeId,
		data: stat.createReport.createNew,
		input: stat.createReport.createNew,
		keys: stat.reportKeys.data ? stat.reportKeys.data.keys : [],
		validGrps: getReportKeysCommonDependencies(state, props),
		selectedKeysName: getSelectedKeysName(state, props),
		selectedGroupsName: getSelectedGroupsName(state, props),
		sortedKeyNamePair: getSortedKeyNamePair(state, props),
		sortedGroupNamePair: getSortedGroupNamePair(state),
		showPreview: stat.createReport.preview,
		dataPreview: stat.reportPreview.data ? stat.reportPreview.data : "",
		saveStatus: adminSt.adminStatus
	}
}

const mapCreateReportsDispatch = (dispatch, props) => {
	return {
		onLoad: () => {
			dispatch(loadCustomReports())
		},
		onClickEdit: (id) => {
			dispatch(openCustomReport(id));
			dispatch(toggleAdminEdit(id, true, false));
		},
		onClickDelete: (id) => {
			dispatch(removeCustomReport(id));
			dispatch(toggleAdminEdit(0, false, false));
		},
		onCloseForm: () => {
			dispatch(toggleAdminEdit(0, false, false));
		},
		onCancel: () => {
			dispatch(toggleAdminEdit(0, false, false));
		},
		onSave: () => {
			dispatch(saveCustomReport());
		},
		showCreateReport: (toggle) => {
			dispatch(toggleCreateReport(toggle));
			dispatch(openCustomReport());
		},
		onSetCreateReport: (field, value) => {
			dispatch(updateCreateReport(field, value));
		},
		onClickPreview: (p) => {
			dispatch(generateReportPreview(p));
		},
		onReorderKey: (keys) => {
			dispatch(reOrderCreateReportKey(keys));
		},
		onRemoveSelKey: (index) => {
			dispatch(removeSelectedKey(index));
		},
		onReorderGroup: (p) => {
			dispatch(reOrderCreateReportGroup(p));
		},
		onRemoveSelGroup: (index) => {
			dispatch(removeSelectedGroup(index));
		},
		onClosePreview: () => {
			dispatch(toggleReportPreview(false));
		}
	};
}

export const CreateReportCtnr = connect(mapCreateReports, mapCreateReportsDispatch)(CreateReport);

const mapScheduledReports = (state, props) => {
	const app = state.app
		, admin = app.admin
		, adminSt = admin.admin
		, stat = state.app.statistics, s = {
		list: stat.scheduleReport.list,
		show: stat.view.schedule.toggle,
		activeView: stat.view.active,
		showInline: adminSt.showInline,
		show: stat.view.createReport.toggle,
		newReport: stat.scheduleReport.createNew, //todo @sue to be removed
		input: stat.scheduleReport.createNew,
		timeZones: timeZoneListSelector(state, props),
		timeStampFormat: timeSpanFormatListSelector(state, props),
		activeScheduled: stat.view.schedule.activeId,
		reportTypes: stat.reports.byId,
		otherOptions: reportOptionsSelector(state, props),
		channels: serverChannelsSelector(state, props),
		areas: filterAreaSelector(state, props),
		agents: onlyActiveAgentsSelector(state, props),
		groups: groupListSelector(state, props),
		saveStatus: adminSt.adminStatus
	};
	return s;
};

const mapScheduledReportsDispatch = (dispatch, props) => {
	return {
		onLoad: () => {
			dispatch(showScheduledReports());
		},
		onClickEdit: (id) => {
			dispatch(openScheduleReport(id));
			dispatch(toggleAdminEdit(id, true, false));
		},
		onClickDelete: (id) => {
			dispatch(removeScheduleReport(id));
			dispatch(toggleAdminEdit(0, false, false));
		},
		showSchedule: toggle => {
			dispatch(toggleSchedule(toggle));
		},
		onSetScheduleReport: (field, value) => {
			dispatch(updateScheduleReport(field, value));
		},
		onResetScheduleReport: () => {
			dispatch(resetScheduleReport());
		},
		onReloadList: () => {
			dispatch(showScheduledReports());
		},
		onFailValidation: (type, input) => {
			dispatch(togglePopAlert(MESSAGE_INVALID_EMAIL + ' ' + input));
		},
		onCloseForm: () => {
			dispatch(toggleAdminEdit(0, false, false));
		},
		onCancel: () => {
			dispatch(toggleAdminEdit(0, false, false));
		},
		onSave: () => {
			dispatch(saveScheduleReport());
		},
	};
};

export const ScheduledReportsCtnr = connect(mapScheduledReports, mapScheduledReportsDispatch)(ScheduledReports);

const mapExportReportDispatch = (dispatch, props) => {
	return {
		onLoad: () => {
			//dispatch action
		}
	};
};

export const ExportReportCtnr = connect(mapScheduleReport, mapExportReportDispatch)(ExportReport);

const mapConfigTimeForm = (state, props) => {
	const stat = state.app.statistics, admin = state.app.admin, adminSt = admin.admin , s = {
		show: stat.view.configTime.toggle,
		activeId: stat.view.configTime.activeId,
		data: stat.configTime.createNew,
		input: stat.configTime.createNew,
		timeFormats: timeSpanItemsListSelector(state, props),
		selectedTimeObj: getSelectedTimeSpanInfo(state,props),
		selectedTimeExample: getSelectedTimeSpanExample(state,props),
		saveStatus: adminSt.adminStatus
	};
	return s;
}
const mapConfigTimeFormDispatch = (dispatch, props) => {
	return {
		onClose: () => {
		},
		onSetConfigTime: (field, value) => {
			dispatch(updateConfigTime(field, value));
		},
		onReorder: (info) => {
			dispatch(reOrderConfigTime(info));
		},
		onRemoveSelection: (index) => {
			dispatch(removeConfigTimeSelection(index));
		},
		onSave: () => {
			dispatch(saveConfigTime());
		},
		onClearSelect: () => {
			dispatch(clearSelectionConfigTime());
		}
	}
}

//TODO: Double check this, seems no longer used but it still on the main app
export const ConfigureTimeFormCtnr = connect(mapConfigTimeForm, mapConfigTimeFormDispatch)(ConfigureTimeFormOld);

const mapConfigTime = (state, props) => {
	const app = state.app
		, admin = app.admin
		, adminSt = admin.admin
		, stat = state.app.statistics, s = {
		list: stat.timespan.data ? stat.timespan.data.timespan : [],
		show: stat.view.configTime.toggle,
		showInline: adminSt.showInline,
		activeId: stat.view.configTime.activeId,
		data: stat.configTime.createNew,
		input: stat.configTime.createNew,
		timeFormats: timeSpanItemsListSelector(state, props),
		selectedTimeObj: getSelectedTimeSpanInfo(state,props),
		selectedTimeExample: getSelectedTimeSpanExample(state,props),
		saveStatus: adminSt.adminStatus
	};
	return s;
}
const mapConfigTimeDispatch = (dispatch, props) => {
	return {
		onClickEdit: (id) => {
			dispatch(openConfigTime(id));
			if(id == 0) {
				dispatch(resetConfigTime());
			}
			dispatch(toggleAdminEdit(id, true, false));
		},
		onClickDelete: (id) => {
			dispatch(removeTimeConfig(id));
			dispatch(toggleAdminEdit(0, false, false));
		},
		onCloseForm: () => {
			dispatch(toggleAdminEdit(0, false, false));
		},
		onCancel: () => {
			dispatch(toggleAdminEdit(0, false, false));
		},
		onClose: () => {
			dispatch(toggleAdminEdit(0, false, false));
		},
		onSetConfigTime: (field, value) => {
			dispatch(updateConfigTime(field, value));
		},
		onReorder: (info) => {
			dispatch(reOrderConfigTime(info));
		},
		onRemoveSelection: (index) => {
			dispatch(removeConfigTimeSelection(index));
		},
		onSave: () => {
			dispatch(saveConfigTime());
		},
		onClearSelect: () => {
			dispatch(clearSelectionConfigTime());
		}
	}
}

export const ConfigureTimeCtnr = connect(mapConfigTime, mapConfigTimeDispatch)(ConfigureTime);

const mapCompareStat = (state, props) => {
	const stat = state.app.statistics, layout = reportChartLayout(state)
		, wf = state.app.workflow
		, { agentTimezoneOffset } = wf.fetchWfSettings.data
		, startDay = getStartDay(agentTimezoneOffset);
	const s = {
		defCurTime: startDay
		, paramId: getStatisticsRoot(state).view.reportParamId
		, compareParamId: getStatisticsRoot(state).view.reportCompareParamId
		, leftPanels: layout[RL_LEFT_PANE]
		, rightPanels: layout[RL_SUB_COMPARE_PANE]
	};
	return s;
}
const mapCompareStatDispatch = (dispatch, props) => {
	return {
		onReload: paramId => {
			dispatch(reloadReportData(paramId));
		},
		onClose: (id, field, val) => {
			dispatch(updateChartParameter(id, field, val)); //FIXME Do this properly
		},
		onParametersChange: (id, field, val) => {
			//FIXME: Weird thigs happens here, check selector
			let pid = id;
			if(typeof id === "object") {
				pid = id.id;
			}
			dispatch(updateChartParameter(pid, field, val));
		}
	}
}

//export const CompareStatCtnrOld = connect(mapCompareStat, mapCompareStatDispatch)(CompareStat);
export const CompareStatCtnr = compose(
	connect(mapCompareStat, mapCompareStatDispatch)
	, connect(mapReportParameter)
)(CompareStat);

const mapLiveReport = (state, props) => {
	const s = {
		view: activeViewMemo(state)
		, queueData: liveReportQueueData(state)
		, activeData: liveReportInConversationData(state)
		, agentsQueueData: liveReportAgentsQueueData(state)
		, groupQueueData: liveReportGroupQueueData(state)
		, sipNumberData: liveReportSIPNumbInUsed(state)
	,
	};
	return s;
}

const mapLiveReportDispatch = (dispatch, props) => {
	return {
		onLoadView: (view) => {
			if(view === LIVE_REPORT_OVERVIEW || view === LIVE_REPORT_CHAT) {
				dispatch(getLiveReportQueueChat());
				dispatch(getAgentWithChatStatus());
			}
			if(view === LIVE_REPORT_OVERVIEW || view === LIVE_REPORT_VOICE) {
				dispatch(sipGetAllAgentList());
				dispatch(sipGetActiveCalls());
				dispatch(sipGetSipNumberInUse());
			}
			if (view === LIVE_REPORT_OVERVIEW || view === LIVE_REPORT_EMAIL) {
				dispatch(fetchAllIncomingEmailErrands());
				dispatch(fetchAllActiveErrands());
				dispatch(fetchAllActiveAgents());
			}
		},
		simpleLoadAndOpen: (encoded, eid) => {
			dispatch(push(V5))
			.then(() => {
				dispatch(decryptAndLoadErrand(encoded, eid));
			});
		},
		onSipSnoop: (mode, sipId) => {
			console.info("mode:"+mode);
			console.info("sipId:"+sipId);
			dispatch(sipStartSnoop(mode, sipId))
		}
	}
}

//Live report and Statistics sharing common redux states
export const LiveReportCtnr = compose(
	connect(mapStatistics, mapStatisticsCallbacks)
	, connect(mapLiveReport, mapLiveReportDispatch)
)(LiveReport);

export default StatisticsCtnr;
