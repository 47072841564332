import { connect } from 'react-redux';
import { push } from '../../common/v5/utils';
import { V5 } from '../../common/path';
import {
	onceAdminTagSimpleList,
	onceAgents,
	onceAreas,
	onceAgentAreas,
	onceConnectedAgentAreas,
	errandPreview,
	setErrandsPriority,
	setErrandsDueDate,
	moveToFolder,
	forwardErrandsToArea,
	decryptAndLoadErrand,
	fetchAreaForwardAgents
} from '../../redux/actions/async/workflow';
import{
	forwardMultipleErrandsToAgent,
	putBackToInbox,
	linkErrand,
	fetchPrintErrand,
	doDeleteErrand,
	doCloseErrand,
	acquireFromOtherAgentErrand,
	fetchAgentWorkingOnSelectedErrands
} from '../../redux/actions/async/errand';
import {
	changeAdminView,
} from '../../redux/actions/admin';
import {
	selectManualReply
} from '../../redux/actions/errand';
import {
	selectManualErrand
} from '../../redux/actions/manual';
import {
	selectToggleSideBar,
	selectCollapseSideBar,
	setMobileView,
	setErrandMobileView,
	setSearchPreviewId,
	controlMultipleActions,
	changeContext,
	resetWorkflowView,
	toggleWorkflowPopup
} from '../../redux/actions/workflow';
import {
	changeSearchText,
	showSearchToggleAction,
	handleSearchTextChange,
	doGlobalSearchByWS,
	unsetSearchSidebar,
	setCurrentSearchSidebar,
	handleSearchFilters,
	handleResetSearchFilter,
	handleSearchFilterArea,
	handleSearchFilterAgent,
	handleSearchFilterChannel,
	handleSearchFilterTag,
	handleToggleDateInterval,
	handleSearchFilterAdvOpt,
	handleSearchFilterErrandType,
	handleSearchFromDate,
	handleSearchToDate,
	handleShowAllByType,
	initAgentSearch,
	handleProcessing,
	handlePagination,
	handeSortDirection,
	handeSortAttribute,
	handleHeaderSearchHide,
	handleFilterByLabel,
	handleFilterLabelByMy,
	handleFilterLabelByAll,
	handleFilterLabelByClose,
	handleResetOffset,
	selectErrandSearchResult,
	selectAllErrandsSearchResult
} from '../../redux/actions/search';
import {
	CTX_SEARCH,
	SEARCH_ERRANDS ,
	ME_START,
	WFP_BULK_SEND_ERRAND
} from '../../common/v5/constants';
import {
	GLOBAL_SEARCH_FROM_BODY,
	SEARCH_TEXT_CLEAR,
	LIST_SHOW_HIDE,
	SELECT_TOP_LIST
} from '../../redux/constants/constants';
import { wfSettingsData } from '../../redux/selectors/common';
import{
	getPreviewFeatures,
	allConnectedAreasSelector,
	isLoadingFwdErrandsAgentsSelector,
	actionsSelectedErrandsSelector,
	forwardErrandsAgentsSelector
} from '../../redux/selectors/workflow';
import Search from './search';
import{
	getSelectedSearchErrandCount,
	searchCheckboxStatesSelector
} from '../../redux/selectors/search';
import { getAgentWorking } from '../../redux/selectors/errand';

const headerSearchTerm = (store) =>{
	let route = store.router;
	if(typeof route !== 'undefined'){
		let loc = route.location;
		if(typeof loc !== 'undefined'){
			let st = loc.state;
			if(typeof st !== 'undefined'){
				return st.searchTerm;
			}
		}
	}
	return "";
}

const mapStateToProp = (st, props) => {
	let d = st.domain, wf = st.app.workflow, header = st.app.header, errand = st.app.errand,
	search = st.app.search,
	selectedCount = getSelectedSearchErrandCount(st),
	s = {
		wfSettings: wfSettingsData(st),
		// areas: filterAreaSelector(st),
		// agents: filterAgentSelector(st),
		// folders: wf.fetchWfSettings.data.userFolders,
		// channels: serverChannelsSelector(st, props),
		// tags: (wf.adminTagList.data !== null ?  wf.adminTagList.data.tags :[]),
		// errandListChat: wf.errandListChat,
		// showOldChat: wf.ui[UI_SHOW_OLD_CHAT],
		// listInputs: wf.listInputs,
		// chatErrandCount: wf.errandListChat.length,
		ui: wf.ui,
		// filter: wf.filter,
		activateChat: st.app.footer.uiData.activateChatWindow,
		searchUI: search.ui.bodySearch,
		searchText: search.inputs.bodyText,
		searchResults: search.results,
		searchFilter: search.ui.globalSearch,
		filterParams: search.ui.filters,
		carryHeaderTerm: headerSearchTerm(st),
		clientAvatar: st.domain.clientAvatar,
		previewId: search.ui.previewId,
		errandPreview: d.previewData.byId[search.ui.previewId],
		previewActivated: getPreviewFeatures(wf.fetchWfSettings.data, CTX_SEARCH),
		hasClosedErrand: (selectedCount.closedErrands > 0 ? true : false),
		selectedErrands: actionsSelectedErrandsSelector(st),
		totalSelected: (selectedCount.allErrands + selectedCount.myErrands + selectedCount.closedErrands),
		myErrandsOnly: (selectedCount.allErrands == 0 && selectedCount.myErrands != 0 && selectedCount.closedErrands == 0) ? true : false,
		allAreas: allConnectedAreasSelector(st, props),
		defaultFilterParams: search.ui.defaultFilters,
		forwardErrandsAgents: forwardErrandsAgentsSelector(st),
		isLoadingFwdErrandsAgents: isLoadingFwdErrandsAgentsSelector(st),
		shouldPopPrint: errand.ui.shouldPopPrint,
		searchCheckboxStates: searchCheckboxStatesSelector(st, props),
		agentWorking: getAgentWorking(st),
	};
	return s;
};

const mapSearchCallbacks = (dispatch, _this) => {
	return {
		onLoad: (wfs, searchTerm, current) => {
			Promise.all(
				[
					dispatch(onceAgents(true))
					, dispatch(onceAgentAreas())
					, dispatch(onceConnectedAgentAreas())
					, dispatch(onceAdminTagSimpleList())
					, dispatch(changeContext(SEARCH_ERRANDS))
				]
			)
			.then(() =>{
				dispatch(initAgentSearch(wfs))
				if(current !== ""){
					dispatch(unsetSearchSidebar());
					dispatch(setCurrentSearchSidebar(current));
				}
				if(searchTerm !== ""){
					dispatch(handleSearchTextChange("body",searchTerm));
				}
				if(wfs.disableActiveSearch == false){
					dispatch(handleProcessing(true));
					dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
				}
			});
			//This should auto hide the search header dropdown
			dispatch(handleHeaderSearchHide(false));
			dispatch(selectToggleSideBar(true));
		},
		onSetActivateErrandMobileView: (toggle) => {
			dispatch(setErrandMobileView(toggle));
		},
		onToggleSideBar: (toggle) => {
			dispatch(selectToggleSideBar(toggle));
		},
		onCollapseSideBar: (toggle) => {
			dispatch(selectCollapseSideBar(toggle));
		},
		onSetMobileView: (toggle) => {
			dispatch(setMobileView(toggle));
		},
		onHandleClear: clean =>{
			dispatch(handleSearchTextChange("body",""));
			dispatch(showSearchToggleAction(SEARCH_TEXT_CLEAR,false));
		},
		onChangeSearchText: event =>{
			dispatch(handleSearchTextChange("body",event.target.value));
			if(event.target.value.length > 0 ){
				dispatch(showSearchToggleAction(SEARCH_TEXT_CLEAR,true));
			}else{
				dispatch(showSearchToggleAction(SEARCH_TEXT_CLEAR,false));
			}
		},
		onHandleSearch: text =>{
			dispatch(handleProcessing(true));
			dispatch(handleResetOffset());
			dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
		},
		onHandleToplistToggle: toggle =>{
			dispatch(showSearchToggleAction(LIST_SHOW_HIDE,toggle));
		},
		onHandleTopListSelect: v =>{
			dispatch(showSearchToggleAction(SELECT_TOP_LIST,v));
			dispatch(handleProcessing(true));
			dispatch(handleResetOffset());
			dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
		},
		setCurrentSearchOpt: opt =>{
			dispatch(unsetSearchSidebar());
			dispatch(setCurrentSearchSidebar(opt));
		},
		onHandleSearchFilter: (optFor, toggle) =>{
			dispatch(handleSearchFilters(optFor, toggle));
		},
		onSearchResetFilter: (obj) => {
			dispatch(handleResetSearchFilter(obj));
			dispatch(handleProcessing(true));
			dispatch(handleResetOffset());
			dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
		},
		handleSelectArea: (aid, disableSearch) =>{
			dispatch(handleSearchFilterArea(aid));
			if(disableSearch == false){
				dispatch(handleProcessing(true));
				dispatch(handleResetOffset());
				dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
			}
		},
		handleSelectAgents: (aid, disableSearch) =>{
			dispatch(handleSearchFilterAgent(aid));
			if(disableSearch == false) {
				dispatch(handleProcessing(true));
				dispatch(handleResetOffset());
				dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
			}
		},
		handleSelectChannels: (cid, disableSearch) =>{
			dispatch(handleSearchFilterChannel(cid));
			if(disableSearch == false) {
				dispatch(handleProcessing(true));
				dispatch(handleResetOffset());
				dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
			}
		},
		handleSelectTags: (tid, disableSearch) =>{
			dispatch(handleSearchFilterTag(tid));
			if(disableSearch == false) {
				dispatch(handleProcessing(true));
				dispatch(handleResetOffset());
				dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
			}
		},
		handleSelectAdvancedOpt: v =>{
			dispatch(handleSearchFilterAdvOpt(v));
		},
		handleSelectErrandTypes: (v, disableSearch) =>{
			dispatch(handleSearchFilterErrandType(v));
			if(disableSearch == false) {
				dispatch(handleProcessing(true));
				dispatch(handleResetOffset());
				dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
			}
		},
		toggleDateInterval: tgl =>{
			dispatch(handleToggleDateInterval(tgl));
		},
		onChangeFromDate: (d, _toDate, disableSearch) =>{
			dispatch(handleSearchFromDate(d));
			if(_toDate && disableSearch == false){
				dispatch(handleProcessing(true));
				dispatch(handleResetOffset());
				dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
			}
		},
		onChangeToDate: (d, _fromDate, disableSearch) =>{
			dispatch(handleSearchToDate(d));
			if(_fromDate && disableSearch == false){
				dispatch(handleProcessing(true));
				dispatch(handleResetOffset());
				dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
			}
		},
		handleSearchButton: () =>{
			dispatch(handleProcessing(true));
			dispatch(handleResetOffset());
			dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
		},
		handleShowAllErrand: t =>{
			dispatch(handleShowAllByType(t));
		},
		handlePaginate: (offset, paginateCtx, selectedPage) =>{
			dispatch(handlePagination(offset, paginateCtx, selectedPage));
			dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
		},
		handleSortDirection: dir =>{
			dispatch(handeSortDirection(dir));
		},
		handleSortAttribute: attr =>{
			dispatch(handeSortAttribute(attr));
		},
		onHandleCloseSearchResultHeader: () => {
			dispatch(handleHeaderSearchHide());
		},
		handleFilterSearch: (v) => {
			dispatch(handleFilterByLabel(v));
			dispatch(handleProcessing(true));
			dispatch(handleResetOffset());
			dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
		},
		handleFilterLabelByMy: (v) =>{
			dispatch(handleFilterLabelByMy(v));
		},
		handleFilterLabelByAll: (v) =>{
			dispatch(handleFilterLabelByAll(v));
		},
		handleFilterLabelByClose: (v) =>{
			dispatch(handleFilterLabelByClose(v));
		},
		onLoadPreview: id => {
			dispatch(setSearchPreviewId(id));
			if(id > 0){
				dispatch(errandPreview(id));
			}
		},
		handleSelectErrand: (id, select) => {
			dispatch(selectErrandSearchResult(id, select));
			dispatch(fetchAgentWorkingOnSelectedErrands());
		},
		handleSelectAllErrand: select => {
			dispatch(selectAllErrandsSearchResult(select));
			dispatch(fetchAgentWorkingOnSelectedErrands());
		},
		onHandleSetPriority: (ids, cipherKeys) => {
			dispatch(setErrandsPriority({list: ids, cipher_keys: cipherKeys, highPriority: true}, true));
		},
		onHandleSetDueDate: (ids, cipherKeys, duedate, errandId) => {
			dispatch(setErrandsDueDate({list: ids, cipher_keys: cipherKeys, done_date: duedate},
					errandId));
		},
		onHandleMultipleErrandAction: (actionFor, show) => {
			dispatch(controlMultipleActions(actionFor, show));
		},
		onHandleMoveToFolder: ({ list, cipher_keys, folder }) => {
			if (list) {
				dispatch(fetchAgentWorkingOnSelectedErrands())
				.then((data) => {
					if(!data.agent_working_on_errand) {
						dispatch(moveToFolder(list, cipher_keys, folder));
					}
				});
			}
		},
		onHandleForwardToArea: (list, cipher_keys, areaID) => {
			dispatch(fetchAgentWorkingOnSelectedErrands())
			.then((data) => {
				if(!data.agent_working_on_errand) {
					dispatch(forwardErrandsToArea(list, cipher_keys, areaID, true));
				}
			});
		},
		onOpenForwardToAgent: areaIds => {
			dispatch(fetchAreaForwardAgents(areaIds));
		},
		onSelectForwardToAgent: (agentId, errandIds, cipherKeys) => {
			dispatch(fetchAgentWorkingOnSelectedErrands())
			.then((data) => {
				if(!data.agent_working_on_errand) {
					dispatch(forwardMultipleErrandsToAgent(
						agentId
						, errandIds
						, cipherKeys
					));
				}
			});
		},
		onHandleLinkErrand: (ids) => {fs
			dispatch(fetchAgentWorkingOnSelectedErrands())
			.then((data) => {
				if(!data.agent_working_on_errand) {
					dispatch(linkErrand(ids));
				}
			});
		},

		onHandleReturnToInbox: (ids) => {
			dispatch(fetchAgentWorkingOnSelectedErrands())
			.then((data) => {
				if(!data.agent_working_on_errand) {
					dispatch(putBackToInbox(ids, true));
				}
			});
		},
		onHandleSendingBulkErrand: (ids) => {
			dispatch(fetchAgentWorkingOnSelectedErrands())
			.then((data) => {
				if(!data.agent_working_on_errand) {
					dispatch(selectManualErrand(ME_START));
					dispatch(selectManualReply(ME_START));
					dispatch(toggleWorkflowPopup(WFP_BULK_SEND_ERRAND, true));
				}
			});
		},
		onHandlePrintErrands: (eids, doPrint) => {
			dispatch(fetchPrintErrand(eids, doPrint));
		},
		onDeleteErrands: (ids) => {
			dispatch(fetchAgentWorkingOnSelectedErrands())
			.then((data) => {
				if(!data.agent_working_on_errand) {
					dispatch(doDeleteErrand(ids, true));
				}
			});
		},
		onCloseErrands: (ids) => {
			dispatch(fetchAgentWorkingOnSelectedErrands())
			.then((data) => {
				if(!data.agent_working_on_errand) {
					dispatch(doCloseErrand(ids, true, false));
				}
			});
		},
		onChangeAdminView: (view, url) => {
			dispatch(push(url)).then(() => dispatch(changeAdminView(view)));
		},
		handleAcquireFromOtherAgentErrand: (...args) => {
			dispatch(acquireFromOtherAgentErrand(...args));
		},
		onMainView: () => {
			dispatch(resetWorkflowView());
		},
		simpleLoadAndOpen: (encoded, eid) => {
			dispatch(push(V5))
			.then(() => {
				dispatch(decryptAndLoadErrand(encoded, eid));
			});
		},
	};
};

const SearchCtnr = connect(mapStateToProp, mapSearchCallbacks)(Search);

export default SearchCtnr;
