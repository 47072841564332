// TODO: remove this. Temporary chat UI by using old chat.
import React from "react";
import {
	Modal,
	ModalBody,
	ModalHeader
} from 'reactstrap';
import ChatBox from '../chat';
import { I } from '../../common/v5/config.js';

export default class Chat extends React.PureComponent {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}
	toggle(state) {
		this.props.onToggleShow(state);
	}
	handleClose() {
		this.props.onToggleShow(false);
	}
	handleEnter() {
	}
	handleExit() {
	}
	handleOpened() {
	}
	handleClosed() {
	}
	render() {
		const {
			show,
			onCancel,
			onConfirm
		} = this.props;
		return <Modal isOpen={show} toggle={this.toggle} fade={false}
				backdrop='static' onClosed={this.handleClosed}
				onEnter={this.handleEnter} onExit={this.handleExit}
				onOpened={this.handleOpened} className='oldchat__modal'>
				<ModalHeader toggle={this.handleClose}>
					<div>{I("Chat")}</div>
				</ModalHeader>
				<ModalBody>
					<ChatBox />
				</ModalBody>
			</Modal>;
	}
}
