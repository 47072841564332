import React,
{
	PureComponent
} from 'react';
import { I, webRoot } from '../../../common/v5/config';
import { BTN_TXT_SAVE, BTN_TXT_SAVING} from '../../../common/v5/constants';
import {
	simplePasswordCheck
} from '../../../common/v5/helpers';
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../../components/v5/ToggleSwitch';
import {
	TextInputRow,
	ReadOnlyTextField,
	BooleanSelect,
	TableIconicButton,
} from '../../../reactcomponents/Form';
import {
	CancelButton
	, SaveButton
	, EditorFooter
} from '../../../reactcomponents/Admin';
import SearchFilter from '../../../reactcomponents/SearchFilter';
import { centionTitleGrey } from '../../../styles/_variables';

export default class SipPriorityNumberForm extends PureComponent {

	constructor(props) {
		super(props);
		this.handleChangeData = this.handleChangeData.bind(this);
		this.handleChangeToggle = this.handleChangeToggle.bind(this);
		this.handleSaveSip = this.handleSaveSip.bind(this);
		this.checkFields = this.checkFields.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	handleChangeData = (e) => {
		const { value, name } = e.target
		this.props.onChangeAdminInput(name, value);
	}

	handleChangeToggle = (value, field) => {
		this.props.onChangeAdminInput(field, value);
	}

	handleDelete = (id) => {
		this.props.onDelete(id);
	}

	checkFields = () => {
		if(this.props.input.sipprionumber.length > 0){
			return true;
		}
		return false;
	}

	handleSaveSip = (e) => {
		if(this.checkFields() == false) {
			return;
		}
		let params = {
			id: this.props.input.prionumberid,
			sipnumber: this.props.input.sipprionumber,
			active: this.props.input.prionumberactive
		};
		this.props.onSaveAdmin(params);
	}

	render() {
		const {
		hidden
		, input
		, onCancel
		, isNew
		, noSipList
		, adminStatus
		} = this.props;

		let buttonSaveTxt = BTN_TXT_SAVE , disableSave = false;
		if(adminStatus && adminStatus.status === 1){
			buttonSaveTxt = BTN_TXT_SAVING;
			disableSave = true;
		}

		return (
			<form id="sipPriorityNumberEdit" className="admin-one-form edit-admin-form" hidden={hidden}>
				<div className='input-section'>
					<ReadOnlyTextField
						id={"prioritynumberid"}
						name={"id"}
						className="admin-text-input"
						label={I("Priority Number ID")}
						value={input.id || ""}
						hidden={true}
					/>
					<TextInputRow
						id={"sipnumber"}
						name={"sipprionumber"}
						className="admin-text-input"
						label={I("SIP Number")}
						value={input.sipprionumber || ""}
						hidden={false}
						onChange={this.handleChangeData}
						mandatory={isNew}
						readonly={!isNew}
					/>
					<ToggleSwitch
						id={"prionumberactive"}
						name={"prionumberactive"}
						label={I("Enabled")}
						checked={input.prionumberactive}
						onClick={this.handleChangeToggle}
						className="admin-text-input"
						/>
					<EditorFooter>
						{!isNew &&
							<TableIconicButton
								type="button"
								className="btn bg-transparent action delete"
								iconClass={"icon-trash"}
								iconPosition={"left"}
								iconSize={"16px"}
								buttonTxtColor={centionTitleGrey}
								label={I("Delete")} title={I("Delete")}
								onClick={()=>{this.handleDelete(input.id)}}
							/>

						}
						<CancelButton onClick={onCancel} />
						<SaveButton onClick={this.handleSaveSip} text={buttonSaveTxt} title={buttonSaveTxt} disabled={disableSave} type="submit"/>
					</EditorFooter>
				</div>
			</form>
		)
	}
}
