import React, {
	Fragment
	, PureComponent
	, memo
	, useMemo
	, useEffect
	, useState
} from 'react';

import { renderFileActionBtns as renderActionButtons } from '../admin';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
// import { renderEditButton } from '../admin';
import { I, webRoot } from '../../../common/v5/config';
import {
	ListHeaderWrapper,
	ListContentWrapper,
	StandardEditorHeader,
	TableHeader,
	Skeleton,
	editorTitleByView,
	LabeledDelete,
	ActionsWrapper,
	Delete as ActionDelete,
	Edit as ActionEdit,
	EditorBox,
	EditorFooter,
	EditorHeader,
	SaveButton,
	AdminListAndEditLayout,
	withEditWrap,
} from '../../../reactcomponents/Admin';
import { TableIconicButton } from '../../../reactcomponents/Form';
import {
	ADMIN_TITLE,
	M_CALL_SIP_TRUNK,
} from '../../../common/v5/constants';
import { SipTrunkForm } from './sipTrunkEdit';
import { customPageTotal } from '../../../reactcomponents/Table';

const EditForm = withEditWrap(SipTrunkForm);

const SipTrunk = ({
	view
	, onLoad
	, showInline
	, list
	, onLoadToEdit
	, onChangeAdminInput
	, onSaveAdmin
	, onDeleteFromList
	, onCloseForm
	, onSetMatch
	, activeId
	, input
	, adminStatus
}) => {
	useEffect(() => {
		if (onLoad) {
			onLoad(M_CALL_SIP_TRUNK, false);
		}
	}, []);

	const handleDelete = (id) => {
		let confirmDelMsg = I('Are you sure you want to delete?');
		if (confirm(confirmDelMsg)) { // TODO: avoid using primitive confirm box
			onDeleteFromList(id);
		}
	}

	const actionDatas = {
		onDelete: onDeleteFromList,
		onEdit: onLoadToEdit
	}
	const columns = [
		{
			dataField: 'id',
			text: 'ID',
			sort: true,
			hidden: true
		},
		{
			dataField: 'trunkname',
			text: 'Trunk name',
			sort: true,
		},
		{
			dataField: 'outboundcallerid',
			text: 'Outbound caller Id',
			sort: true,
		},
		{
			isDummyField: true,
			dataField: 'action',
			text: 'Action',
			headerClasses: 'center',
			formatter: (cell, row) => {
				return renderActionButtons(cell, row, actionDatas);
			},
		}
	];

	const defaultSorted = [{
		dataField: 'createtime',
		order: 'desc'
	}];

	const isNew = activeId <= 0;

	const options = {
		paginationSize: 3,
		pageStartIndex: 1,
		hideSizePerPage: true, // Hide the sizePerPage dropdown always
		hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
		firstPageText: '<<',
		prePageText: '<',
		nextPageText: '>',
		lastPageText: '>>',
		showTotal: true,
		paginationTotalRenderer: customPageTotal,
		disablePageTitle: true,
		sizePerPageList: [10] //todo: give user the option to choose instead of fixed value
	};

	let listSection = <Fragment>
		<ListHeaderWrapper>
			<TableHeader
				title={ADMIN_TITLE[view]}
				type='button'
				// className='plus-btn btn bg-transparent'
				iconClass='icon-add'
				onClickAdd={e => onLoadToEdit(0)}
				hide={false}
				hideAddIcon={false}
				showInline={showInline}
			/>
		</ListHeaderWrapper>
		<ListContentWrapper className={"v5-table-style"}>
			<BootstrapTable
				keyField="id"
				data={list}
				noDataIndication={I("No data to display")}
				columns={columns}
				bordered={false}
				condensed={false}
				defaultSorted={defaultSorted}
				pagination={paginationFactory(options)}
				hover
			/>
		</ListContentWrapper>
	</Fragment>;

	let editSection = <Fragment>
		<EditorHeader
			hidden={!showInline}
			isNew={isNew}
			onClickClose={onCloseForm}
			title={editorTitleByView(isNew, view)}
		/>
		<EditForm
			activeId={activeId}
			input={input}
			view={view}
			onChangeAdminInput={onChangeAdminInput}
			onSaveAdmin={onSaveAdmin}
			onCancel={onCloseForm}
			onDelete={handleDelete}
			onSetMatch={onSetMatch}
			isNew={isNew}
			hidden={!showInline}
			adminStatus={adminStatus}
		/>
	</Fragment>;

	return (
		<AdminListAndEditLayout
			className={"siptrunk-page"}
			listSection={listSection}
			editSection={editSection}
		/>
	);
}

export default SipTrunk;
