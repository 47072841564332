import React, { useState, useReducer } from 'react';
import { I } from '../../../common/v5/config';
import { AddTextItemInputContainer } from '../../../reactcomponents/AddTextItemInput';
import { BTN_TXT_SAVE, BTN_TXT_SAVING } from '../../../common/v5/constants';
import {
	TextInputRow,
	TableIconicButton,
	FormInputWithLabelRow
} from '../../../reactcomponents/Form';
import {
	TabContent
	, TabPane
	, Nav
} from 'reactstrap';
import { OneNavItem } from '../../../reactcomponents/NavTabs';
import {
	CancelButton
	, SaveButton
	, EditorFooter
} from '../../../reactcomponents/Admin';
import { centionTitleGrey } from '../../../styles/_variables';
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../../components/v5/ToggleSwitch';
import KanbanBoard from '../groups/draggables';

const TAB_GENERAL = 1
	, TAB_SIP = 2

const TAB_SIP_GENERAL = 1
	, TAB_SIP_CODEC = 2
	, TAB_SIP_REGISTRATION = 3


const SipTrunkIdentifyHelper = () => {
	return (
		<div>
            {I("A comma-separated list of ways the Endpoint or AoR can be identified.")}
			<br/>
            {I("\"username\": Identify by the From or To username and domain.")}
			<br/>
            {I("\"auth_username\": Identify by the Authorization username and realm.")}
			<br/>
            {I("\"ip\": Identify by the source IP address.")}
			<br/>
            {I("\"header\": Identify by a configured SIP header value.")}
			<br/>
            {I("In the username and auth_username cases, if an exactmatch on both username and domain/realm fails, the match is retried with just the username. (default: \"username,ip\")")}
			<br/>
		</div>
	);
}

const identifybyHelper = <SipTrunkIdentifyHelper />;

const sipCodecReducer = (state, {type, source, destination, id, value}) => {
	if (type == "MOVE_SIP_CODEC_SINGLE") {
		let newSource = state.codecs;
		let [itemData] = newSource.splice(source.index, 1);
		newSource.splice(destination.index, 0, itemData);
		newSource.forEach((item, index) => {
			item.order = index;
		});
		return {
			...state,
			["codecs"]: newSource
		};
	} else if(type == "SIP_CODEC_TOGGLE"){
		let newSource = state.codecs;
		newSource.forEach((item, index) => {
			if(item.id == id) {
				item.enabled = value;
				return;
			}
		});
		return {
			...state,
			["codecs"]: newSource
		};
	}
	return state;
}

export const SipTrunkForm = ({
	hidden
	, input
	, onSaveAdmin
	, onCancel
	, onChangeAdminInput
	, onSetMatch
	, isNew
	, adminStatus
}) => {
	const [state, dispatch] = useReducer(sipCodecReducer, input);
	const [activeTab, setActiveTab] = useState(TAB_GENERAL);
	const [activeSipTab, setActiveSipTab] = useState(TAB_SIP_GENERAL);
	const [checkedItems, setCheckedItems] = useState([]);
	let toggle = new Map();

	const onDragStart = ({ source, destination, ...args }) => {
	}

	const onDragEnd = ({ source, destination, ...args }) => {
		// Ignore if the drop is outside a valid destination.
		if (!destination) return;
		// Ignore if the drop is in the original position.
		if (
			source.droppableId === destination.droppableId &&
			source.index === destination.index
		) return;
		dispatch({
			type: "MOVE_SIP_CODEC_SINGLE",
				source,
				destination
		});

	}

	const handleChangeData = (e) => {
		const { value, name } = e.target
		onChangeAdminInput(name, value);
	}

	const addTrunkMatch = (id, text) => {
		onSetMatch(id, text, false);
	}

	const removeTrunkMatch = (id, index) => {
		onSetMatch(id, index, true);
	}

	const handleItemClick = (id, column) => {
	}
	const handleItemToggle = (value, id) => {
		dispatch({
				type: "SIP_CODEC_TOGGLE",
				source: 0,
				destination: 0,
				id: id,
				value: value
		});
	}
	const handleChangeToggle = (value, field) => {
		toggle[field]= value
		onChangeAdminInput(field, value)
	}

	const handleDelete = (id) => {
		onDelete(id);
	}

	const checkFields = () => {
		if(input.trunkname == "") {
			console.info("trunkname is empty");
			return false;
		}
		if(input.outboundcallerid == "") {
			console.info("outboundcallerid is empty");
			return false;
		}
		return true;
	}

	const handleSaveTrunk = (e) => {
		if(checkFields() == false) {
			return;
		}
		let params = {
			id: input.id,
			name: input.trunkname,
			outboundcallerid: input.outboundcallerid,
			enabled: input.siptrunkenabled,
			username: input.username,
			secret: input.secret,
			sipserver: input.sipserver,
			serverport: input.serverport,
			transport: input.transport,
			context: input.context,
			codecs: JSON.stringify(input.codecs),
			siptrunkmatches: JSON.stringify(input.siptrunkmatches),
			identifyby: input.identifyby,
			clienturi: input.clienturi,
			serveruri: input.serveruri,
			outboundproxy: input.outboundproxy,
			contactuser: input.contactuser,
			sipregenabled: input.sipregenabled,
			registrationretryinterval: input.registrationretryinterval,
			regforbidretryinterval: input.regforbidretryinterval,
			regfatalretryinterval: input.regfatalretryinterval,
			regexpiration: input.regexpiration,
		};
		onSaveAdmin(params);
	}

	let codecMap = { codecs: { list: input.codecs }};
	let idMatches = input.siptrunkmatches.length > 0 ? input.siptrunkmatches:[];

	let buttonSaveTxt = BTN_TXT_SAVE , disableSave = false;
	if(adminStatus && adminStatus.status === 1) {
		buttonSaveTxt = BTN_TXT_SAVING;
		disableSave = true;
	}

	return (
		<div>
		<form id="sipTrunkEdit" className="admin-one-form edit-admin-form" hidden={hidden}>
			<div className='edit-admin-form'>
			<section className={"export-general-tabs"}>
				<Nav tabs>
					<OneNavItem
						active={activeTab === TAB_GENERAL}
						onClick={(v) => { setActiveTab(v) }}
						tabId={TAB_GENERAL}
						text={I("General")}
					/>
					<OneNavItem
						active={activeTab === TAB_SIP}
						onClick={(v) => { setActiveTab(v) }}
						tabId={TAB_SIP}
						text={I("SIP")} 
					/>
				</Nav>
				<TabContent activeTab={activeTab}>
					<TabPane tabId={TAB_GENERAL}>
						<TextInputRow
							id={"id"}
							name={"id"}
							className="admin-text-input"
							value={input.id || ""}
							hidden={true}
							readOnly={true}
						/>
						<TextInputRow
							id={"trunkname"}
							name={"trunkname"}
							className="admin-text-input"
							label={I("Trunk Name")}
							value={input.trunkname || ""}
							maxLength={40}
							hidden={false}
							onChange={handleChangeData}
							mandatory={true}
						/>
						<TextInputRow
							id={"outboundcallerid"}
							name={"outboundcallerid"}
							className="admin-text-input"
							label={I("Outbound Caller ID")}
							value={input.outboundcallerid || ""}
							hidden={false}
							onChange={handleChangeData}
							mandatory={true}
						/>
						<ToggleSwitch
							id={"siptrunkenabled"}
							name={"siptrunkenabled"}
							label={I("Enabled")}
							checked={input.siptrunkenabled}
							onClick={handleChangeToggle}
							className="admin-text-input"
						/>
					</TabPane>
					<TabPane tabId={TAB_SIP}>
						<Nav tabs>
							<OneNavItem
								active={activeSipTab === TAB_SIP_GENERAL}
								onClick={(v) => { setActiveSipTab(v) }}
								tabId={TAB_SIP_GENERAL}
								text={I("General")}
							/>
							<OneNavItem
								active={activeSipTab === TAB_SIP_REGISTRATION}
								onClick={(v) => { setActiveSipTab(v) }}
								tabId={TAB_SIP_REGISTRATION}
								text={I("Outbound Registration")} 
							/>
							<OneNavItem
								active={activeSipTab === TAB_SIP_CODEC}
								onClick={(v) => { setActiveSipTab(v) }}
								tabId={TAB_SIP_CODEC}
								text={I("Codec")} 
							/>
							<TabContent id="adminEditSipTab" activeTab={activeSipTab}>
								<TabPane tabId={TAB_SIP_GENERAL}>
									<TextInputRow
										id={"username"}
										name={"username"}
										className="admin-text-input"
										label={I("Username")}
										value={input.username || ""}
										hidden={false}
										onChange={handleChangeData}
										mandatory={true}
										helperTxt={I("Id to login to the trunk server")}
									/>
									<TextInputRow
										id={"secret"}
										name={"secret"}
										className="admin-text-input"
										label={I("Secret")}
										value={input.secret || ""}
										hidden={false}
										onChange={handleChangeData}
										mandatory={true}
										helperTxt={I("Password login to the trunk server")}
									/>
									<TextInputRow
										id={"sipserver"}
										name={"sipserver"}
										className="admin-text-input"
										label={I("SIP server")}
										value={input.sipserver || ""}
										hidden={false}
										onChange={handleChangeData}
										mandatory={true}
										helperTxt={I("Hostname or IP address of the SIP trunk server")}
									/>
									<TextInputRow
										id={"serverport"}
										name={"serverport"}
										className="admin-text-input"
										label={I("SIP server port")}
										value={input.serverport || ""}
										hidden={false}
										onChange={handleChangeData}
										mandatory={true}
										helperTxt={I("Port to use to connect to the SIP trunk server")}
									/>
									<TextInputRow
										id={"context"}
										name={"context"}
										className="admin-text-input"
										label={I("Context")}
										value={input.context || ""}
										hidden={false}
										onChange={handleChangeData}
										mandatory={true}
										helperTxt={I("Dialplan context to use for incoming calls")}
									/>
									<TextInputRow
										id={"transport"}
										name={"transport"}
										className="admin-text-input"
										label={I("SIP Transport")}
										value={input.transport || ""}
										hidden={false}
										onChange={handleChangeData}
										mandatory={false}
										helperTxt={I("ID of the transport to use. Contact your SIP server Admin for more information")}
									/>
									<TextInputRow
										id={"identifyby"}
										name={"identifyby"}
										className="admin-text-input"
										label={I("Identify By")}
										value={input.identifyby || ""}
										hidden={false}
										onChange={handleChangeData}
										mandatory={false}
										helperTxt={identifybyHelper}
									/>
										<FormInputWithLabelRow
											label={I("Matches")}
											warning={""}
											inlineLabel={true}
										>
										<div style={{width: "70%"}}>
											<AddTextItemInputContainer id={"add-matches"} name={"add-matches"} onAdd={addTrunkMatch} onRemove={removeTrunkMatch} list={idMatches} />
										</div>
										</FormInputWithLabelRow>
								</TabPane>
								<TabPane tabId={TAB_SIP_REGISTRATION}>
									<TextInputRow
										id={"clienturi"}
										name={"clienturi"}
										className="admin-text-input"
										label={I("Client URI")}
										value={input.clienturi || ""}
										hidden={false}
										onChange={handleChangeData}
										mandatory={false}
										helperTxt={I("Client SIP URI used when attemping outbound registration")}
									/>
									<TextInputRow
										id={"serveruri"}
										name={"serveruri"}
										className="admin-text-input"
										label={I("Server URI")}
										value={input.serveruri || ""}
										hidden={false}
										onChange={handleChangeData}
										mandatory={false}
										helperTxt={I("SIP URI of the server to register against")}
									/>
									<TextInputRow
										id={"outboundproxy"}
										name={"outboundproxy"}
										className="admin-text-input"
										label={I("Outbound proxy")}
										value={input.outboundproxy || ""}
										hidden={false}
										onChange={handleChangeData}
										mandatory={false}
										helperTxt={I("SIP URI of the server to register against")}
									/>
									<TextInputRow
										id={"contactuser"}
										name={"contactuser"}
										className="admin-text-input"
										label={I("Contact user")}
										value={input.contactuser || ""}
										hidden={false}
										onChange={handleChangeData}
										mandatory={false}
										helperTxt={I("On outgoing requests, force the user portion of the Contact header to this value")}
									/>
									<TextInputRow
										id={"registrationretryinterval"}
										name={"registrationretryinterval"}
										className="admin-text-input"
										label={I("Retry interval")}
										value={input.registrationretryinterval|| "60"}
										hidden={false}
										onChange={handleChangeData}
										mandatory={false}
										helperTxt={I("Interval in seconds between retries if outbound registration is unsuccessful")}
									/>
									<TextInputRow
										id={"regforbidretryinterval"}
										name={"regforbidretryinterval"}
										className="admin-text-input"
										label={I("Forbidden retry interval")}
										value={input.regforbidretryinterval || "0"}
										hidden={false}
										onChange={handleChangeData}
										mandatory={false}
										helperTxt={I("Interval used when receiving a 403 Forbidden response")}
									/>
									<TextInputRow
										id={"regfatalretryinterval"}
										name={"regfatalretryinterval"}
										className="admin-text-input"
										label={I("Fatal retry interval")}
										value={input.regfatalretryinterval || "0"}
										hidden={false}
										onChange={handleChangeData}
										mandatory={false}
										helperTxt={I("Interval used when receiving a fatal response. (default: \"0\") A fatal response is any permanent failure (non-temporary 4xx, 5xx, 6xx) response received from the registrar. NOTE - if also set the 'forbidden_retry_interval' takes precedence over this one when a 403 is received. Also, if 'auth_rejection_permanent' equals 'yes' a 401 and 407 become subject to this retry interval.")}
									/>
									<TextInputRow
										id={"regexpiration"}
										name={"regexpiration"}
										className="admin-text-input"
										label={I("Expiration")}
										value={input.regexpiration || "3600"}
										hidden={false}
										onChange={handleChangeData}
										mandatory={false}
										helperTxt={I("Expiration time for registrations in seconds")}
									/>
									<ToggleSwitch
										id={"sipregenabled"}
										name={"sipregenabled"}
										label={I("SIP registration enabled")}
										checked={input.sipregenabled}
										onClick={handleChangeToggle}
										className="admin-text-input"
									/>
								</TabPane>
								<TabPane tabId={TAB_SIP_CODEC}>
									<KanbanBoard
										title={("Turn on the desired codecs. Drag to reorder")}
										checkBox={false}
										data={codecMap}
										itemField={"name"}
										onDragStart={onDragStart}
										onDragEnd={onDragEnd}
										checkedItems={checkedItems}
										useToggle={true}
										onItemClick={handleItemClick}
										onItemToggle={handleItemToggle}
									/>
								</TabPane>
							</TabContent>
						</Nav>
					</TabPane>
				</TabContent>
				<EditorFooter>
					{!isNew &&
						<TableIconicButton
							type="button"
							className="btn bg-transparent action delete"
							iconClass={"icon-trash"}
							iconPosition={"left"}
							iconSize={"16px"}
							buttonTxtColor={centionTitleGrey}
							label={I("Delete")} title={I("Delete")}
							onClick={()=>{handleDelete(input.id)}}
						/>

					}
					<CancelButton onClick={onCancel} />
					<SaveButton onClick={handleSaveTrunk} text={buttonSaveTxt} title={buttonSaveTxt} disabled={disableSave} type="submit"/>
				</EditorFooter>
			</section>
			</div>
		</form>
		</div>
	)
}
