import React, { Fragment, useState, useEffect, useCallback } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { renderFileActionBtns as renderActionButtons } from '../admin';
import {
  ListHeaderWrapper,
  ListContentWrapper,
  TableHeader,
  EditorFooter,
  EditorHeader,
  SaveButton,
  CancelButton,
  AdminListAndEditLayout,
  withEditWrap,
  CustomButton
} from '../../../reactcomponents/Admin';
import { ADMIN_TITLE, BTN_TXT_SAVE, M_SKILLS } from '../../../common/v5/constants';
import {
	useCallbackMultiValues,
} from '../../../hooks/callback'
 import { AlertWrapper } from "../admin";
 import { DismissableAlert } from '../../../reactcomponents/Error';
 import { customPageTotal } from "../../../reactcomponents/Table";
 import {
	TextInputRow,
	SelectInputRow,
	FormInputWithLabelRow
} from '../../../reactcomponents/Form';
import { I } from "../../../common/v5/config";
import PopupPage from "../../../reactcomponents/PopupPage";
import { ActionsWrapper, Edit, Remove } from "../../../reactcomponents/Admin";
import Helper from '../../../reactcomponents/Helper';
import { DeletePopup } from "../../../reactcomponents/Dialog";

const TableOptions = {
	paginationSize: 3,
	pageStartIndex: 1,
	hideSizePerPage: true, // Hide the sizePerPage dropdown always
	hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
	firstPageText: '<<',
	prePageText: '<',
	nextPageText: '>',
	lastPageText: '>>',
	showTotal: true,
	paginationTotalRenderer: customPageTotal,
	disablePageTitle: true,
	sizePerPageList: [10] //todo: give user the option to choose instead of fixed value
};

export const AddAgentBtnStyle = {
	height: '24px',
	width: '24px',
	borderRadius: '4px',
	padding: '4px',
	cursor: 'pointer',
	fontSize: '16px',
}

export const renderSkillAgentActions = (
	cell
	, { id }
	, { onDelete, onEdit }
) => {
	return (
		<ActionsWrapper>
			{onEdit && <Edit id={id} onClick={() => onEdit(id)} />}
			{onDelete && <Remove
				id={id}
				onClick={(() => onDelete(id))}
			/>}
		</ActionsWrapper>
	)
}

const SkillEditForm = ({
	hidden = false
	, activeId
	, input
	, categoryList
	, agentPerSkillList
	, agentList
	, proficiencyList
	, baseButtons
	, onChangeAdminInput
	, onHandleTextInputBlur
	, onSaveCategory
	, onDeleteCategory
	, onSaveAgentIntoSkill
	, onDeleteAgentSkill
}) => {
	const [showSkillModal, setShowSkillModal] = useState(false);
	const [toAddNewCategory, setToAddNewCategory] = useState(false);
	const [selectAgentMode, setSelectAgentMode] = useState(false);
	const [selectedAgentToEdit, setSelectedAgentToEdit] = useState(null);

	const handleChangeName = (e) => {
		let field = e.target.name, value = e.target.value;
		onChangeAdminInput(field, value);
	}

	const handleChangeDesc = (e) => {
		let field = e.target.name, value = e.target.value;
		onChangeAdminInput(field, value);
	}

	const openCreateCategoryModal = () => {
		onChangeAdminInput("skillCategoryName", "");
		onChangeAdminInput("skillCategoryDesc", "");
		setToAddNewCategory(true);
		setShowSkillModal(true);
	}

	const openEditCategoryModal = () => {
		if(input.skillCategoryId) {
			categoryList.forEach((item) => {
				if(item.id === input.skillCategoryId) {
					onChangeAdminInput("skillCategoryName", item.name);
					onChangeAdminInput("skillCategoryDesc", item.description);
				}
			});
		}
		setToAddNewCategory(false);
		setShowSkillModal(true);
	}

	const openSelectAgentModal = () => {
		setSelectAgentMode(true);
		setShowSkillModal(true);
	}

	const handleCloseSkillModal = () => {
		setShowSkillModal(false);
		setSelectAgentMode(false);
	}

	const handleSaveCategory = () => {
		setShowSkillModal(false);
		const param = {
			id: (toAddNewCategory ? 0 : input.skillCategoryId),
			name: input.skillCategoryName,
			description: input.skillCategoryDesc
		}
		onSaveCategory(param);
	}

	const handleSaveAgentIntoSkill = (agent) => {
		setShowSkillModal(false);
		const param = {
			id: agent.id ? agent.id : 0,
			skillId: activeId,
			agentId: agent.agent,
			proficiencyId: agent.proficiency
		}
		onSaveAgentIntoSkill(param);
	}

	const handleSelectCategory = (id) => {
		onChangeAdminInput("skillCategoryId", id);
	}

	const handleDeleteCategory = (id) => {
		const confirmDelMsg = I('Are you sure you want to delete?');
		if (confirm(confirmDelMsg)) {
			onDeleteCategory(id);
			setShowSkillModal(false);
		}
	}

	const handleEditAgentSkill = (id) => {
		const agentList = agentPerSkillList;
		agentList.forEach((item) => {
			if(item.id === id) {
				setSelectedAgentToEdit({
					agent: item.agentId,
					proficiency: item.proficiency,
					id: item.id
				});
			}
		});
		openSelectAgentModal();
	}

	const handleDeleteAgentSkill = (id) => {
		const confirmDelMsg = I('Are you sure you want to delete?');
		if (confirm(confirmDelMsg)) {
			onDeleteAgentSkill(id);
		}
	}

	const editCategoryBtnStyle = {
		border: '1px solid #EAEAEA',
		height: '24px',
		width: '24px',
		borderRadius: '4px',
		padding: '4px',
		cursor: 'pointer'
	}

	const agentCols = [
		{
			dataField: 'id',
			text: 'ID',
			sort: true,
			hidden: true
		},
		{
			dataField: 'name',
			text: I('Name'),
			sort: true,
		},
		{
			dataField: 'proficiency',
			text: I('Proficiency'),
			sort: true,
			formatter: (cell, row) => {
				let profName = "";
				proficiencyList.forEach((item) => {
					if (item.id === cell) {
						profName = item.name;
					}
				});
				return profName;
			},
		},
		{
			isDummyField: true,
			dataField: 'id',
			text: I('Action'),
			headerClasses: 'center',
			formatter: (cell, row) => {
				return renderSkillAgentActions(cell, row, actionDatas);
			},
		}
	];

	const rowEvents = {
		onClick: (e, row) => {
			//return onLoadToEdit(row.id, M_SKILLS, false, list);
		}
	};

	const actionDatas = {
		onEdit: handleEditAgentSkill
		, onDelete: handleDeleteAgentSkill
	}

	return (
		<Fragment>
			<form id="skillAdminForn" className="admin-one-form edit-admin-form skills-admin-form" hidden={hidden}>
				<FormInputWithLabelRow
					label={I('Name')}
					mandatory={true}
					inlineLabel={false}
				>
					<TextInputRow
						name="skillName"
						className="admin-text-input with-helper"
						value={input.skillName}
						onChange={handleChangeName}
						onBlur={onHandleTextInputBlur}
						warning={""}
						inlineLabel={false}
						mandatory={true}
					/>
				</FormInputWithLabelRow>
				<FormInputWithLabelRow
					label={I('Description')}
					inlineLabel={false}
				>
					<TextInputRow
						id={"admin-skillDesc"}
						name="skillDesc"
						className="admin-text-input with-helper"
						value={input.skillDesc}
						onChange={handleChangeDesc}
						onBlur={onHandleTextInputBlur}
						inlineLabel={false}
					/>
				</FormInputWithLabelRow>
				<FormInputWithLabelRow
					label={I('Category')}
					mandatory={true}
					inlineLabel={false}
				>
					<div className="row" style={{width: '100%'}}>
						<div className="col-11 col-md-11">
							<SelectInputRow
								id={"admin-skillCategory"}
								name={"skillCategory"}
								className={"admin-select"}
								option={categoryList}
								mandatory={true}
								value={input.skillCategoryId}
								textNoItemSelected={I("Please select")}
								onSelect={handleSelectCategory}
								readonly={false}
							/>
						</div>
						<div className="col-1 col-md-1" style={{display: 'flex', gap: '8px'}}>
							<div style={editCategoryBtnStyle} onClick={openEditCategoryModal} hidden={input.skillCategoryId ? false : true}>
								<i style={{color: '#969696'}} className="icon-pencil"></i>
							</div>
							<div style={editCategoryBtnStyle} onClick={openCreateCategoryModal}>
								<i style={{color: '#969696'}} className="icon-add"></i>
							</div>
						</div>
					</div>
				</FormInputWithLabelRow>
				{ activeId > 0 &&
					<Fragment>
						<div className="row" style={{ width: '100%' }}>
							<div className="col-11 col-md-11">
								<FormInputWithLabelRow
									label={I('Agent with skill')}
									inlineLabel={false}
									helperTxt={I('Agents with this skill')}
								>
								</FormInputWithLabelRow>
							</div>
							<div className="col-1 col-md-1" style={{ display: 'flex', gap: '8px' }}>
								<div style={AddAgentBtnStyle} onClick={openSelectAgentModal}>
									<i style={{ color: '#6D6D6D' }} className="icon-add"></i>
								</div>
							</div>
						</div>
						<ListContentWrapper className={"v5-table-style"}>
							<BootstrapTable
								keyField="id"
								data={agentPerSkillList}
								noDataIndication={I("No data to display")}
								columns={agentCols}
								bordered={false}
								condensed={false}
								defaultSorted={ [{
									dataField: 'name',
									order: 'asc'
								}]}
								pagination={paginationFactory(TableOptions)}
								rowEvents={ rowEvents }
								hover
							/>
						</ListContentWrapper>
					</Fragment>
				}
				{
					showSkillModal &&
					<SkillUpdateModal show={showSkillModal} isNew={toAddNewCategory} selectedAgent={selectedAgentToEdit} selectAgentMode={selectAgentMode} agentList={agentList} proficiencyList={proficiencyList} onClose={handleCloseSkillModal}
						onChangeInput={onChangeAdminInput} onSaveAgent={handleSaveAgentIntoSkill} onSave={handleSaveCategory} onDelete={handleDeleteCategory} input={input} />
				}
				<div style={{ marginTop: '50px' }}>
					{baseButtons}
				</div>
			</form>
		</Fragment>
	)
}

export const SkillUpdateModal = ({
	show,
	isNew,
	input = {},
	selectAgentMode,
	selectSkillMode,
	selectedAgent,
	agentList,
	proficiencyList,
	selectedSkill,
	skillList,
	onClose,
	onChangeInput,
	onSaveAgent,
	onSave,
	onDelete
}) => {
	const [agent, setAgent] = useState(null);
	const [proficiency, setProficiency] = useState(null);
	const [skill, setSkill] = useState(null);

	useEffect(() => {
		if(selectedAgent) {
			setAgent(selectedAgent.agent);
			setProficiency(selectedAgent.proficiency);
		}
		if(selectedSkill) {
			setSkill(selectedSkill.skill);
			setProficiency(selectedSkill.proficiency);
		}
	}, []);

	let title = isNew ? I("Create skill category") : I("Edit skill category");
	let titleIcon = isNew ? "icon-add" : "icon-edit";
	if(selectAgentMode) {
		title = I("Add agent with skill");
		if(selectedAgent !== null) {
			title = I("Edit agent with skill");
			titleIcon = "icon-edit";
		}
	} else if (selectSkillMode) {
		title = I("Add skill");
		if(selectedSkill && selectedSkill.skill != 0) {
			title = I("Edit skill");
			titleIcon = "icon-edit";
		}
	}
	const handleChangeInput = (e) => {
		let field = e.target.name, value = e.target.value;
		if(onChangeInput) {
			onChangeInput(field, value);
		}
	}
	const handleSelectAgent = (id) => {
		setAgent(id);
	}
	const handleSelectSkill = (id) => {
		setSkill(id);
	}
	const handleSelectProficiency = (id) => {
		setProficiency(id);
	}
	const handleSave = () => {
		if(selectAgentMode) {
			const agentInfo = {
				agent: agent,
				proficiency: proficiency
			}
			if(selectedAgent && selectedAgent.id) {
				agentInfo.id = selectedAgent.id;
			} else {
				agentInfo.id = 0;
			}
			onSaveAgent(agentInfo);
		} else if(selectSkillMode){
			onSave(skill, proficiency);
		} else {
			if(onSave) {
				onSave();
			}
		}
		if(onClose) {
			onClose();
		}
	}
	const handleDelete = () => {
		if(!selectAgentMode && !selectSkillMode) {
			if(input.skillCategoryId) {
				onDelete(input.skillCategoryId);
			}
		} else if(selectSkillMode) {
			if(selectedSkill && selectedSkill.id) {
				onDelete(selectedSkill.id);
			}
		}
	}
	let saveTxt = isNew ? I("Create") : BTN_TXT_SAVE;
	if(selectAgentMode) {
		saveTxt = I("Add agent");
		if(selectedAgent) {
			saveTxt = BTN_TXT_SAVE;
		}
	} else if(selectSkillMode) {
		saveTxt = BTN_TXT_SAVE
	}
	return <PopupPage
		data-qa-id="skill-category-backdrop-popup"
		extraClass="skill-category-backdrop"
		innerClass="skill-category"
		show={show}
		onClose={onClose}
	>	<div id="" style={{width: '100%'}}>
			<div className="popup-title">
				<h2><i className={titleIcon}></i>{title}</h2>
			</div>
			<div className="popup-content">
				{!selectAgentMode && !selectSkillMode &&
					<Fragment>
						<FormInputWithLabelRow
							label={I('Name')}
							mandatory={true}
							inlineLabel={false}
						>
							<TextInputRow
								name="skillCategoryName"
								className="admin-text-input with-helper"
								value={input.skillCategoryName ? input.skillCategoryName : ""}
								onChange={handleChangeInput}
								warning={""}
								inlineLabel={false}
								mandatory={true}
							/>
						</FormInputWithLabelRow>
						<FormInputWithLabelRow
							label={I('Description')}
							inlineLabel={false}
						>
							<TextInputRow
								id={"admin-skillCategoryDesc"}
								name="skillCategoryDesc"
								className="admin-text-input with-helper"
								value={input.skillCategoryDesc ? input.skillCategoryDesc : ""}
								onChange={handleChangeInput}
								inlineLabel={false}
							/>
						</FormInputWithLabelRow>
					</Fragment>
				}
				{selectAgentMode &&
					<Fragment>
						<FormInputWithLabelRow
							label={I('Agent')}
							mandatory={false}
							inlineLabel={false}
						>
							<SelectInputRow
								id={"admin-skillAgent"}
								name={"skillAgent"}
								className={"admin-select"}
								option={agentList}
								mandatory={true}
								value={agent}
								textNoItemSelected={I("Please select")}
								onSelect={handleSelectAgent}
								readonly={false}
							/>
						</FormInputWithLabelRow>
						<FormInputWithLabelRow
							label={I('Proficiency')}
							mandatory={false}
							inlineLabel={false}
						>
							<SelectInputRow
								id={"admin-skillAgent"}
								name={"skillAgent"}
								className={"admin-select"}
								option={proficiencyList}
								mandatory={true}
								value={proficiency}
								textNoItemSelected={I("Please select")}
								onSelect={handleSelectProficiency}
								readonly={false}
							/>
						</FormInputWithLabelRow>
					</Fragment>
				}
				{
					selectSkillMode &&
					<Fragment>
						<FormInputWithLabelRow
							label={I('Skill')}
							mandatory={false}
							inlineLabel={false}
						>
							<SelectInputRow
								id={"admin-skillAgent"}
								name={"skillAgent"}
								className={"admin-select"}
								option={skillList}
								mandatory={true}
								value={skill}
								textNoItemSelected={I("Please select")}
								onSelect={handleSelectSkill}
								readonly={false}
							/>
						</FormInputWithLabelRow>
						<FormInputWithLabelRow
							label={I('Proficiency')}
							mandatory={false}
							inlineLabel={false}
						>
							<SelectInputRow
								id={"admin-skillAgent"}
								name={"skillAgent"}
								className={"admin-select"}
								option={proficiencyList}
								mandatory={true}
								value={proficiency}
								textNoItemSelected={I("Please select")}
								onSelect={handleSelectProficiency}
								readonly={false}
							/>
						</FormInputWithLabelRow>
					</Fragment>
				}
				<EditorFooter
					left={
						<div className="admin-footer-btn" hidden={isNew}>
							<CustomButton className={"bg-transparent btn-transparent"} icon={"icon-trash"} onClick={handleDelete} text={I("Remove")} />
						</div>
					}
				>
					<SaveButton className={"btn-blue"} onClick={handleSave} text={saveTxt} />
				</EditorFooter>
			</div>
		</div>
	</PopupPage>
}

const EditForm = withEditWrap(SkillEditForm);

const EditFormButtons = ({
	onCancel,
	onDelete,
	onSave,
}) => {
	return (
		<EditorFooter
			left={
				<div className="admin-footer-btn">
					<CustomButton className={"bg-transparent btn-transparent"} icon={"icon-trash"} onClick={onDelete} text={I("Remove")} />
				</div>
			}
		>
			<CancelButton onClick={onCancel} />
			<SaveButton className={"btn-blue"} onClick={onSave} text={I('Save')} />
		</EditorFooter>
	)
}

const agentFormatter = (cell, row) => {
	const totalAgentsCount = cell;
	const agentStr = row.agentNames;
	let agentArr = [];
	if(agentStr != null && agentStr !== "") {
		agentArr = agentStr.split(",");
	}
	const agentCount = <span style={{color: '#6d6d6d'}}>{totalAgentsCount}</span>
	return (
		<Helper
			className="group__agent--tooltip"
			triggerElement={agentCount}
			hideTooltip={totalAgentsCount === 0}
		>
			<ul style={{lineHeight: "2em", maxHeight: "50vh"}}>
				{
				   agentArr.map((agent, index) => {
					return <li key={agent+index}>{`${agent}`}</li>
				   })
				}
			</ul>
		</Helper>
	)
}

const Skills = ({
	view
	, onLoad
	, showInline
	, list
	, categoryList
	, proficiencyList
	, agentList
	, agentPerSkillList
	, onLoadToEdit
	, onChangeAdminInput
	, onCancel
	, onDeleteFromList
	, onCloseForm
	, activeId
	, input
	, adminAlert
	, onDismissAlert
	, onSaveCategory
	, onSaveSkills
	, onDeleteCategory
	, onSaveAgentIntoSkill
	, onDeleteAgentSkill
}) => {
	const [showDeleteAlert, setShowDeleteAlert] = useState(false);
	const [toBeDeleted , setToBeDeleted] = useState(0);
	const [deleteWarning, setDeleteWarning] = useState("");
	const [deleteWarningMsg, setDeleteWarningMsg] = useState("");

	useEffect(() => {
		if (onLoad) {
			onLoad(M_SKILLS);
		}
		return () => {
			onCloseForm();
			onDismissAlert();
		}
	}, []);

	const handleEdit = (id) => {
		onLoadToEdit(id, M_SKILLS, false, list);
		onDismissAlert();
	}

	const handleDelete = (id) => {
		setShowDeleteAlert(true);
		setToBeDeleted(id);

		let areaCount, agentCount = 0;
		list.forEach((item) => {
			if(item.id === id) {
				areaCount = item.areas;
				agentCount = item.agents;
			}
		});
		const warningTitle = I("Are you sure you want to delete?");
		let warning = "";
		if(areaCount > 0 || agentCount > 0) {
			warning += I("This skill is used in ");
		}
		if(areaCount > 0) {
			warning += " " + areaCount + I(" area(s).");
		}
		if(agentCount > 0) {
			warning += " " + agentCount + I(" agent(s).");
		}
		setDeleteWarning(warningTitle);
		setDeleteWarningMsg(warning);
	}

	const handleConfirmDelete = () => {
		setShowDeleteAlert(false);
		onDeleteFromList(toBeDeleted);
		setToBeDeleted(0);
	}

	const handleCancelDelete = () => {
		setShowDeleteAlert(false);
		setToBeDeleted(0);
	}

	const actionDatas = {
		onEdit: handleEdit
		, onDelete: handleDelete
	}

	const columns = [
		{
			dataField: 'id',
			text: 'ID',
			sort: true,
			hidden: true
		},
		{
			dataField: 'name',
			text: I('Name'),
			sort: true,
		},
		{
			dataField: 'agents',
			text: I('Agents'),
			sort: true,
			formatter: agentFormatter,
		},
		{
			dataField: 'categoryName',
			text: I('Skill category'),
			sort: true,
		},
		{
			dataField: 'description',
			text: I('Description'),
			sort: true,
		},
		{
			isDummyField: true,
			dataField: 'action',
			text: I('Action'),
			headerClasses: 'center',
			formatter: (cell, row) => {
				return renderActionButtons(cell, row, actionDatas);
			},
		}
	];

	const defaultSorted = [{
		dataField: 'name',
		order: 'asc'
	}];

	const isNew = activeId <= 0;

	const rowEvents = {
		onClick: (e, row) => {
			onDismissAlert();
			return onLoadToEdit(row.id, M_SKILLS, false, list);
		}
	};

	const rowClasses = (row) => {
		let classes = null;
		if (row.id === activeId) {
			classes = 'active-row';
		}
		return classes;
	};

	const handleSaveSkills = () => {
		const param = {
			name: input.skillName,
			description: input.skillDesc,
			id: activeId,
			category: input.skillCategoryId,
		}
		onSaveSkills(param);
	}

	const baseButtons = <EditFormButtons
		hideDelete={isNew}
		onCancel={useCallbackMultiValues(onCancel, activeId)}
		onDelete={useCallbackMultiValues(handleDelete, activeId)}
		onSave={handleSaveSkills}
	/>

	const handleTextInputChange = (e) => {
		let field = e.target.name, value = e.target.value;
		onChangeAdminInput(field, value);
	}

	let listSection = <Fragment>
		<AlertWrapper id={"SkillAdminAlert"}  hidden={adminAlert.show ? false : true} onDismiss={onDismissAlert}>
			<DismissableAlert show={adminAlert.show ? true : false}
				role={adminAlert.type === "error" ? "danger" : "success"}
				text={adminAlert.msg}
				icon={adminAlert.type === "error" ? "icon-caution" : "icon-new-tick-single"}
				onDismiss={onDismissAlert}
			/>
		</AlertWrapper>
		<ListHeaderWrapper>
			<TableHeader
				title={ADMIN_TITLE[view]}
				type='button'
				iconClass='icon-add'
				onClickAdd={e => onLoadToEdit(0)}
				hide={false}
				hideAddIcon={false}
				showInline={showInline}
			/>
		</ListHeaderWrapper>
		<ListContentWrapper className={"v5-table-style"}>
			<BootstrapTable
				keyField="id"
				data={list}
                noDataIndication={I("No data to display")}
				columns={columns}
				bordered={false}
				condensed={false}
				defaultSorted={defaultSorted}
				pagination={paginationFactory(TableOptions)}
				rowEvents={ rowEvents }
				rowClasses={ rowClasses }
				hover
			/>
		</ListContentWrapper>
	</Fragment>;

	let editSection = <Fragment>
		<EditorHeader
			hidden={!showInline}
			isNew={isNew}
			onClickClose={onCloseForm}
			title={isNew ? I("Add skill") : I("Edit skill")}
		/>
		<EditForm
			activeId={activeId}
			input={input}
			view={view}
			categoryList={categoryList}
			proficiencyList={proficiencyList}
			agentPerSkillList={agentPerSkillList}
			agentList={agentList}
			onTextInputChange={handleTextInputChange}
			onChangeAdminInput={onChangeAdminInput}
			onSave={onSaveSkills}
			onCancel={onCloseForm}
			onDelete={handleDelete}
			isNew={isNew}
			hidden={!showInline}
			baseButtons={baseButtons}
			onSaveCategory={onSaveCategory}
			onDeleteCategory={onDeleteCategory}
			onSaveAgentIntoSkill={onSaveAgentIntoSkill}
			onDeleteAgentSkill={onDeleteAgentSkill}
		/>
		<DeletePopup
			title={deleteWarning}
			msg={deleteWarningMsg}
			icon={'icon-caution'}
			show={showDeleteAlert}
			onDelete={handleConfirmDelete}
			onClose={handleCancelDelete}
		/>
	</Fragment>;

	return <AdminListAndEditLayout
		className={"call-ivr-page"}
		listSection={listSection}
		editSection={editSection}
	/>
}

export default Skills;