import React, { PureComponent, memo, useMemo, Fragment } from 'react';
import update from 'immutability-helper';
import classNames from 'classnames';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import styled from 'styled-components';
import {
	CUSTOM_REPORTS
	, ERRANDS_REPORT
	, ET_PDF
	, EXECUTIVE_REPORT
	, EXPORTS_REPORT
	, ORGANIZATION_REPORT
	, SATISFACTION_METER_REPORT
	, SCHEDULED_REPORT
	, STATISTICS_TITLE
	, SYSTEM_REPORTS
	, VIEW_REPORT
	, CREATE_REPORT
	, CONFIGURE_TIME
	, MM_STATISTICS
	, SR_ACTIVE_AGENT
	, CENTION_LOGO
	, CENTION_LOGO_MINI
} from '../../common/v5/constants';
import {
	composeWithDisplayName
	, withUnmountWhenHidden
} from '../../reactcomponents/hocs';
import Breadcrumb from '../../reactcomponents/Breadcrumb';
import {
	ExportAction
	, HeaderActions
	, ScheduleAction
	, ShareAction
	, CreateNewAction
	, CreateNewTimeAction
	, ToggleMoreHeaderActions
	, HideableHeaderActions
	, ButtonIcon
} from '../../reactcomponents/HeaderActions';
import SearchFilter from '../../reactcomponents/SearchFilter';
import { I, L } from '../../common/v5/config';
import Link, { CancellableSimpleLink } from '../../containers/link';
import MenuCtnr from '../../common/v5/menuCtnr';
import ToggleSideBar from '../../components/v5/ToggleSideBar';
import { SidebarSettingsCtnr, WorkflowInnerContainerCtnr, IconizeSideBarMenuCtnr } from './workflowCtnr';
import { TopFilter } from '../../components/v5/TopFilter';
import { withOverviewContentHeight } from '../../styles/connect/statistic';
import { BreadcrumbItem } from 'reactstrap';
import {
	ExecutiveReportCtnr,
	ReportCtnr,
	SatisfactionMeterReportCtnr,
	ErrandsReportCtnr,
	ExportReportCtnr,
	ScheduledReportsCtnr,
	CreateReportCtnr,
	ConfigureTimeCtnr
} from './statisticsCtnr';
import { isMobile } from '../../redux/util';
import { SkeletonLoader } from '../../components/v5/SkeletonFrames';
import { StatisticsSidePanel } from './statistics/statisticsUI';
import { AlertWrapper } from "./admin";
import { DismissableAlert } from '../../reactcomponents/Error';
import { isValidTelavoxUrl } from '../../common/v5/helpers';

export class ReportTitle extends PureComponent {
	handleClick = (e) => {
		let p = this.props;
		p.onBackToMain(p.mainView, e);
	}
	render() {
		const { view, title, level } = this.props;
		return (
			<Breadcrumb>
				<BreadcrumbItem tag="span" href="#" onClick={this.handleClick}>
					<MenuCtnr visible={true} hoverable={true} />
				</BreadcrumbItem>
				<BreadcrumbItem tag="a" href="#" active={level === 2 ? true : false}>{view}</BreadcrumbItem>
				{
					level > 2 &&
					<BreadcrumbItem active tag="span">{title}</BreadcrumbItem>
				}
			</Breadcrumb>
		);
	}
}

class ViewReports extends PureComponent {
	constructor(props) {
		super(props);
		this.handleSelectReport = this.handleSelectReport.bind(this);
	}
	handleSelectReport(id, isCustomReport) {
		if (this.props.onSelectReport) {
			this.props.onSelectReport(id, isCustomReport);
		}
	}
	render() {
		let systemReports = [], customReports = [];
		$.each(this.props.reports, function (k, v) {
			if (v.GroupBy === SYSTEM_REPORTS) {
				systemReports.push(<li
					key={"view_reports_" + v.Id}
					data-qa-id={"view_reports_" + v.Name}
					id={v.Id}
					className={this.props.selected === v.Id ? "current" : ""}
					onClick={(e) => this.handleSelectReport(v.Id, false)}
				>
					<a>{v.i18nName}</a>
				</li>);
			} else if (v.GroupBy === CUSTOM_REPORTS) {
				customReports.push(<li
					key={"view_reports_" + v.Id}
					data-qa-id={"view_reports_" + v.Name}
					id={v.Id}
					className={this.props.selected === v.Id ? "current" : ""}
					onClick={(e) => this.handleSelectReport(v.Id, true)}
				>
					<a>{v.i18nName}</a>
				</li>);
			}
		}.bind(this));
		return (
			<div className="view-reports-list">
				{systemReports.length > 0 &&
					<div className="system-reports-list">
						<h4>{L(SYSTEM_REPORTS)}</h4>
						<ul>{systemReports}</ul>
					</div>
				}
				{customReports.length > 0 &&
					<div className="custom-reports-list">
						<h4>{L(CUSTOM_REPORTS)}</h4>
						<ul>{customReports}</ul>
					</div>
				}
			</div>
		);
	}
}

//
export const TopStatFilter = ({ reportsType, onSelectFilter, ...props }) => (
	<TopFilter>
		<SearchFilter name={"search_reports"} label={I("Search reports")} data={reportsType} fields={{ id: "Id", value: "i18nName" }} onSelectFilter={onSelectFilter} />
		<div className="stats-top-filter">
			<ViewReports {...props} />
		</div>
	</TopFilter>
)

const SideBarMenuBorder = ({ children, ...props }) => <ul className="sidebar-menu" {...props}>{children}</ul>;

const SideBarMenuItem = ({ children, "data-qa-id": qaId, ...props }) => <li data-qa-id={qaId} {...props}>{children}</li>;

const DrilldownMenu = ({ pathPrefix }) => (
	<SideBarMenuBorder>
		<SideBarMenuItem data-qa-id="drilldown_report">
			<Link
				to={{ pathname: `${pathPrefix}reports#drilldown` }}
				data-qa-id={"drilldown_link"}
			>
				{I("Drill Down Report")}
			</Link>
		</SideBarMenuItem>
	</SideBarMenuBorder>
);

const ExportPDF = (reportName) => {
	const input = document.getElementById('report-content'); // TODO: code like this is not good in react
	input.style.height = input.scrollHeight + 'px';
	html2canvas(input)
		.then((canvas) => {
			var imgData = canvas.toDataURL('image/png');
			var imgWidth = 210;
			var pageHeight = 295;
			var imgHeight = canvas.height * imgWidth / canvas.width;
			var heightLeft = imgHeight;

			var doc = new jsPDF('p', 'mm');
			var position = 0;

			doc.addImage(imgData, 'PNG', 2, position, imgWidth, imgHeight);
			heightLeft -= pageHeight;

			while (heightLeft >= 0) {
				position = heightLeft - imgHeight;
				doc.addPage();
				doc.addImage(imgData, 'PNG', 2, position, imgWidth, imgHeight);
				heightLeft -= pageHeight;
			}
			doc.save(`${reportName}.pdf`);
			document.getElementById('report-content').style.height = "";
		})
}

const StyledOverviewEditorDIV = styled.div`
	width: 50px;
	height: 30px;
	float: right;
`
const OverviewEditorDIVBase = ({ onDoubleClick }) => (
	<StyledOverviewEditorDIV onDoubleClick={onDoubleClick} />
);

const OverviewEditorDIV = composeWithDisplayName(
	'OverviewEditorDIV'
	, memo
	, withUnmountWhenHidden
)(OverviewEditorDIVBase);

const Div = withOverviewContentHeight("div");

const Section = withOverviewContentHeight("section");

class Statistics extends PureComponent {
	constructor(props) {
		super(props);
		this.handleSideBarToggle = this.handleSideBarToggle.bind(this);
		this.handleSideBarCollapse = this.handleSideBarCollapse.bind(this);
		this.changeView = this.changeView.bind(this);
		this.switchPage = this.switchPage.bind(this);
		this.handleExport = this.handleExport.bind(this);
		this.handleSchedule = this.handleSchedule.bind(this);
		this.handleCreateNew = this.handleCreateNew.bind(this);
		this.handleCreateNewTime = this.handleCreateNewTime.bind(this);
		this.handleSearchReport = this.handleSearchReport.bind(this);
		this.handleShare = this.handleShare.bind(this);
		this.handleShowOverviewEditor = this.handleShowOverviewEditor.bind(this);
		this.handleShowOverviewEditorTrue = this.handleShowOverviewEditorTrue.bind(this);
		this.handleShowStatMenuList = this.handleShowStatMenuList.bind(this);
		this.handleHideStatMenuList = this.handleHideStatMenuList.bind(this);
		this.handleShowLiveReports = this.handleShowLiveReports.bind(this);
		this.handleMessageEvent = this.handleMessageEvent.bind(this);
		this.state = {
			filteredReports: [],
			filteredKeywords: '',
			showActionsMobile: false,
			showFiltersMobile: false,
			showCollapsedStatMenu: false,
			showOverviewEditor: false,
			showLiveReportMenu: false,
			customReportSelected: false
		}
	}
	componentDidMount() {
		if (typeof this.props.onLoad === 'function') {
			this.props.onLoad();
		}
		window.addEventListener('resize', this.handleStatisticResize);
		window.addEventListener('message', this.handleMessageEvent);
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleStatisticResize);
		window.removeEventListener('message', this.handleMessageEvent);
	}
	handleShowOverviewEditor(show) {
		this.setState({ showOverviewEditor: show });
	}
	handleShowOverviewEditorTrue() {
		this.handleShowOverviewEditor(true);
	}
	handleSideBarToggle() {
		this.props.onToggleSideBar(!this.props.ui.showSideBar);
	}
	handleSideBarCollapse() {
		this.props.onCollapseSideBar(!this.props.ui.collapseSideBar);
	}
	changeView(page, e) {
		this.props.onChangeView(page);
		if (this.props.ui.showMobileView) {
			this.props.onToggleSideBar(false);
		}
	}
	switchPage(id, isCustom) {
		if (!this.props.reports.length) {
			return;
		}
		const report = this.props.reportsById[id];
		this.props.onSwitchReport(
			VIEW_REPORT
			, report
			, { id }
		);
		if (this.props.ui.showMobileView) {
			this.props.onToggleSideBar(false);
		}
		this.setState({customReportSelected: isCustom})
	}
	handleSchedule() {
		this.props.showSchedule(true);
	}
	handleCreateNew() {
		this.props.showCreateReport(true);
	}
	handleCreateNewTime() {
		this.props.showCreateConfigTime(true);
	}
	handleShare() {
		this.props.showShareReport(true);
	}
	handleExport(v) {
		const { activeId, exportParameter, onExport } = this.props
			;
		if (typeof onExport !== "function") {
			return;
		}
		if (v === ET_PDF) {
			return ExportPDF(exportParameter.reportName);
		}
		onExport(
			activeId
			, update(exportParameter, { $merge: { exportFormat: v } })
		);
	}
	handleSearchReport(r, currentInput) {
		this.setState({ filteredKeywords: currentInput });
		this.setState({ filteredReports: r });
	}
	handleShowStatMenuList() {
		this.setState({ showCollapsedStatMenu: true });
	}
	handleHideStatMenuList() {
		this.setState({ showCollapsedStatMenu: false });
	}
	handleClickStatMenu(id) {
		console.log("open ", id);
	}
	handleStatisticResize = () => {
		if (isMobile) {
			this.props.onSetMobileView(true);
			this.props.onToggleSideBar(false);
		} else {
			this.props.onSetMobileView(false);
			this.props.onToggleSideBar(true);
		}
	}
	toggleStatActions = () => {
		this.setState({ showActionsMobile: !this.state.showActionsMobile });
	}
	handleClickOutside = () => {
		this.setState({ showActionsMobile: false })
	}
	toggleStatsFilter = () => {
		this.setState({ showFiltersMobile: !this.state.showFiltersMobile })
	}
	handleHomePage = (e) => {
		e.preventDefault();
		this.props.onMainView();
	}
	handleShowLiveReports() {
		this.setState({ showLiveReportMenu: !this.state.showLiveReportMenu });
	}
	handleMessageEvent(e) {
		if(typeof e.data === "object"){
			if(typeof e.data.action !== 'undefined' &&
				e.data.action !== null){
				if(e.data.action == "open-errand"){
					let urlValid = isValidTelavoxUrl(e.origin);
					if(typeof e.data.params !== 'undefined' &&
						e.data.params !== null && urlValid == true){
						externalqueue.isPostMessage = true;
						if(typeof e.data.params.deepLink !== 'undefined' &&
							e.data.params.deepLink !== null &&
							e.data.params.deepLink !== ""){
								let encoded = e.data.params.deepLink;
								this.props.simpleLoadAndOpen(encoded, 0);
						}
					}
				} else {
					console.info("unhandled messsage action:", e.data.action);
				}
			}
		}
	}
	handleNavigatePage = (id, isCustom) => {
		this.setState({customReportSelected: isCustom})
		this.switchPage(id, isCustom)
	}
	render() {
		if (!this.props.ready) {
			return <SkeletonLoader
				hide={false}
				view={"statistic"}
				collapseSideBar={this.props.ui.collapseSideBar}
				showSideBar={this.props.ui.showSideBar}
				showConversationList={false}
			/>;
		}
		const p = this.props, ui = p.ui, st = this.state;
		let statActButtons = []
			, layoutClass = "layout slim statistics"
			, appClass = 'app-content statistics-overview'
			, allReports = []
			;

		if (ui.collapseSideBar) {
			appClass += " collapsed";
		}

		if (p.activeView === VIEW_REPORT) {
			statActButtons.push(
				<ScheduleAction
					key='btn-schedule-report'
					id='btn-schedule-report'
					onClick={this.handleSchedule}
				/>
			);
			statActButtons.push(
				<ShareAction
					key='btn-share-report'
					id='btn-share-report'
					hide={false}
					onClick={this.handleShare}
				/>
			);
			statActButtons.push(
				<ExportAction
					key='btn-export-report'
					id='btn-export-report'
					onClick={this.handleExport}
				/>
			);
		}

		if (!p.ui.showSideBar) {
			layoutClass += " closed-sidebar"
		}
		if (p.activateChat) {
			layoutClass += " opened-chat";
		}
		if (p.activeView !== EXECUTIVE_REPORT) {
			appClass += ' statistic';
		}
		if (!features["cention-reports"] &&
			features["agent-data-overview-statistics"]) {
			allReports.push(p.reportByName[SR_ACTIVE_AGENT]);
		} else if (this.state.filteredReports.length > 0) {
			allReports = this.state.filteredReports;
			$.each(this.state.filteredReports, function (k, v) {
				v.GroupBy = this.props.reportsById[v.Id].GroupBy;
			}.bind(this));
			allReports = this.state.filteredReports;
		} else {
			allReports = p.reports;
		}
		let navLevel = 2;
		if (p.activeView === VIEW_REPORT) {
			navLevel = 3;
		}
		let hasActions = statActButtons.length;
		let sidebarClass = "", logoWrapperStyle = {}, flexMenusStyle = {};
		if (ui.collapseSideBar) {
			sidebarClass = "collapsed";
			logoWrapperStyle = { margin: "auto", textAlign: "center" }
			flexMenusStyle = { padding: "0px" };
		}
		const hideLogo = features['hide-cention-logo'];
		let customStyledAppHeader = { borderRadius: "initial" }
		if (hideLogo && ui.collapseSideBar) {
			// customStyledAppHeader = { borderRadius: "initial", padding: "0px 15px" }
		}
		const { systemReports, customReports } = p.reportGroup
		const defaultSystemReport = systemReports[0];
		const defaultCustomReport = customReports[0];

		let systemReportselected = (p.activeView === VIEW_REPORT && !this.state.customReportSelected)
		let customReportselected = (p.activeView === VIEW_REPORT && this.state.customReportSelected)
		let sidepanelMenuList = customReportselected ? customReports: systemReports;
		let sidePanelTitle = customReportselected ? I("Custom Report") : I("System Report")

		return (
			<div className="statistic-page admin-main-wrapper">
				<div id="page-wrapper">
					<Section className={layoutClass} data-app-layout>
						<div className={classNames("app-sidebar", sidebarClass)}>
							<div className="app-header" style={customStyledAppHeader}>
								{(hideLogo) &&
									<MenuCtnr visible={!ui.showErrandMobileView && !ui.collapseSideBar} />
								}
								{(hideLogo) &&
									<ToggleSideBar visible={ui.showSideBar || ui.showMobileView}
										collapsed={ui.collapseSideBar}
										mobileMode={ui.showMobileView}
										onClick={this.handleSideBarToggle}
										onCollapse={this.handleSideBarCollapse} />
								}
								<div className="side-logo" style={logoWrapperStyle} hidden={hideLogo}>
									<CancellableSimpleLink onClick={this.handleHomePage} title={I("Back to Main")}>
										<img src={ui.collapseSideBar ? CENTION_LOGO_MINI : CENTION_LOGO} alt="" />
									</CancellableSimpleLink>
								</div>
							</div>
							<WorkflowInnerContainerCtnr className="app-inner-content" data-custom-scrollbar>
								<div className="flex-menus-wrap" style={flexMenusStyle}>
									<div className="side-bar-main-menu" hidden={hideLogo}>
										{
											!ui.collapseSideBar &&
											<div style={{ marginRight: "55px" }}><MenuCtnr visible={!ui.showErrandMobileView} /></div>
										}
										<ToggleSideBar visible={ui.showSideBar || ui.showMobileView}
											showSideBar={ui.showSideBar}
											collapsed={ui.collapseSideBar}
											mobileMode={ui.showMobileView}
											id={"QA_toggle_sidebar_top"}
											onCollapse={this.handleSideBarCollapse}
											onClick={this.handleSideBarToggle} />
									</div>
									{
										ui.collapseSideBar &&
										<div>
											<IconizeSideBarMenuCtnr
												currentPage={MM_STATISTICS}
												activeView={p.activeView}
												showWorkflowMenu={true}
												systemReportselected={systemReportselected}
												customReportselected={customReportselected}
												defaultSystemReport={defaultSystemReport}
												defaultCustomReport={defaultCustomReport}
												onNavigateSideMenu={this.handleNavigatePage}
											/>
										</div>
									}
									{/* new menu component -- TODO: refactor after cflag(2023-02-28.CCC-5316.redesign.statistics.menu) is safe to use*/}
									<div className="statistics-menu">
										{features["cention-reports"] &&
											<div className='sidebar-search-filter'>
												<SearchFilter hide={ui.collapseSideBar} name={"search_reports"} label={I("Search reports")} data={p.reports} fields={{ id: "Id", value: "i18nName" }} onSelectFilter={this.handleSearchReport} />
												<ul className="filtered-menu system-reports" hidden={ui.collapseSideBar}>
													<ViewReports reports={this.state.filteredReports} selected={p.activeId} onSelectReport={this.switchPage} />
												</ul>
											</div>
										}
										<ul className="sidebar-menu main" hidden={ui.collapseSideBar || this.state.filteredKeywords}>
										{(features["cention-reports"] || features["admin.show-executive-report"]) &&
											<Fragment>
												<li data-tooltip data-qa-id="exec_report_link" className={p.activeView === EXECUTIVE_REPORT ? "current" : ""} onClick={(e) => this.changeView(EXECUTIVE_REPORT)}>
													<a>{I("Executive Report")}</a>
												</li>
											</Fragment>
										}
										{!features["cention-reports"] && features["agent-data-overview-statistics"] &&
										<ul className="sidebar-menu system-reports" hidden={ui.collapseSideBar}>
											<ViewReports reports={allReports} selected={p.activeId} onSelectReport={this.switchPage} />
										</ul>
										}
										{features["cention-reports"] &&
											<Fragment>
												<li data-qa-id="system_report_link" className={systemReportselected ? "current" : ""} title={I("System Report")} onClick={(e) => this.handleNavigatePage(defaultSystemReport.Id, false)}>
													<a title={I("System Report")}>{I("System Report")}</a>
												</li>
												<li data-qa-id="custom_report_link" className={customReportselected ? "current" : ""} title={I("Custom Report")} onClick={(e) => this.handleNavigatePage(defaultCustomReport.Id, true)}>
													<a title={I("Custom Report")}>{I("Custom Report")}</a>
												</li>
												<li id={"list-side-menu-" + CREATE_REPORT} data-qa-id="create_report_link" className={p.activeView === CREATE_REPORT ? "current" : ""} title={I("Create reports")} onClick={(e) => this.changeView(CREATE_REPORT)}>
													<a title={I("Create reports")}>{I("Create reports")}</a>
												</li>
												<li data-qa-id="schedule_report_link" className={p.activeView === SCHEDULED_REPORT ? "current" : ""} title={I("Scheduled reports")} onClick={(e) => this.changeView(SCHEDULED_REPORT)}>
													<a title={I("Scheduled reports")}>{I("Scheduled reports")}</a>
												</li>
												<li data-qa-id="config_time_link" className={p.activeView === CONFIGURE_TIME ? "current" : ""} title={I("Configure Time")} onClick={(e) => this.changeView(CONFIGURE_TIME)}>
													<a title={I("Configure Time")}>{I("Configure Time")}</a>
												</li>
											</Fragment>
										}
										</ul>
									</div>
								</div>
								<SidebarSettingsCtnr />
							</WorkflowInnerContainerCtnr>
						</div>
						<Div className={appClass}>
							<div className="app-header statistic" style={customStyledAppHeader}>
								<ToggleSideBar
									visible={!ui.showSideBar || ui.collapseSideBar}
									collapsed={ui.collapseSideBar}
									mobileMode={ui.showMobileView}
									wrapped={true}
									onClick={this.handleSideBarToggle}
									onCollapse={this.handleSideBarCollapse} />
								{ui.collapseSideBar &&
									<TopStatFilter reports={allReports} reportsType={p.reports} selected={p.activeId} onSelectReport={this.switchPage} onSelectFilter={this.handleSearchReport} />
								}
								<ReportTitle mainView={EXECUTIVE_REPORT} view={STATISTICS_TITLE[p.activeView]} title={p.title} level={navLevel} onBackToMain={this.changeView} />
								<HideableHeaderActions
									outsideClickIgnoreClass="ignore-header-actions-onclickoutside"
									className={
										classNames(
											'errand-nav'
											, 'c3-header-actions v5'
											, { "show-nav": this.state.showActionsMobile }
										)}
									hide={false}
									onClickOutside={this.handleClickOutside}
								>
									<div className='actions-container'>
										{statActButtons}
									</div>
								</HideableHeaderActions>
								<ButtonIcon
									className={classNames("btn toggle-filters", { "active": st.showFiltersMobile })}
									hidden={!p.activeId || !ui.showMobileView}
									icon={"fas fa-filter"}
									onClick={this.toggleStatsFilter}
									data-qa-id={"mobile-btn-toggleStatsFilter"}
									noTooltip={true}
								/>
								<HeaderActions
									className={classNames(
										"mobile-toggle-options"
									)}
									hide={!hasActions || !ui.showMobileView}
								>
									<ToggleMoreHeaderActions
										key={"btn-toggleHeaderActions"}
										className="pull-right transparent"
										onClick={this.toggleStatActions}
									/>
								</HeaderActions>
								<OverviewEditorDIV
									hidden={p.activeView !== EXECUTIVE_REPORT}
									onDoubleClick={this.handleShowOverviewEditorTrue}
								/>
							</div>
							{
								(p.adminAlert && p.adminAlert.show) ?
								<AlertWrapper id={"StatisticAlert"} onDismiss={p.onDismissAlert}>
									<DismissableAlert show={p.adminAlert.show ? true : false}
										role={p.adminAlert.type === "error" ? "danger" : "success"}
										text={p.adminAlert.msg}
										icon={p.adminAlert.type === "error" ? "icon-caution" : "icon-new-tick-single"}
										onDismiss={p.onDismissAlert}
									/>
								</AlertWrapper>
								: ""
							}
							{
								p.activeView === EXECUTIVE_REPORT &&
								<ExecutiveReportCtnr
									onChangeShowEditor={this.handleShowOverviewEditor}
									show={true}
									showEditor={this.state.showOverviewEditor}
								/>
							}
							{
								p.activeView === ORGANIZATION_REPORT &&
								<ReportCtnr />
							}
							{
								p.activeView === VIEW_REPORT &&
									<StatisticsSidePanel
										reports={p.reports}
										list={sidepanelMenuList}
										selected={p.activeId}
										onSelectReport={this.switchPage}
										title={sidePanelTitle}
										hidden={!features["cention-reports"]}
										isCustomSelected={this.state.customReportSelected}
									>
										<ReportCtnr show={st.showFiltersMobile} mobileView={ui.showMobileView} />
									</StatisticsSidePanel>
							}
							{
								p.activeView === SATISFACTION_METER_REPORT &&
								<SatisfactionMeterReportCtnr />
							}
							{
								p.activeView === ERRANDS_REPORT &&
								<ErrandsReportCtnr />
							}
							{
								p.activeView === CREATE_REPORT &&
								<CreateReportCtnr />
							}
							{
								p.activeView === SCHEDULED_REPORT &&
								<ScheduledReportsCtnr />
							}
							{
								p.activeView === EXPORTS_REPORT &&
								<ExportReportCtnr />
							}
							{
								p.activeView === CONFIGURE_TIME &&
								<ConfigureTimeCtnr />
							}
						</Div>
					</Section>
				</div>
			</div>
		)
	}
}

export default Statistics;
