import React, {
	Fragment
	, PureComponent
	, memo
	, useMemo
	, useEffect
	, useState
} from 'react';
import { I, webRoot } from '../../../common/v5/config';
import { AreaDropdown } from '../../../reactcomponents/Dropdown';
import {
	ADMIN_VIEW_MAP,
	INPUT_EMPTY_WARNING
	, FORM_SUBMIT_EMPTY_WARNING
} from '../../../common/v5/constants';
import {
	TextInputRow,
	SelectInputRow,
	ReadOnlyTextField,
	CustomInputRow,
	TableIconicButton
} from '../../../reactcomponents/Form';

const AreaRoutingMofo = {
	offset:{enabled:false},
	preventOverflow:{enabled:false},
}

const RoutingGroupsForm = ({
	hidden
	, input
	, onHandleTextInputChange
	, onChangeAdminInput
	, baseButtons
	, isNew
	, onSave
}) => {

	const warnTxt = INPUT_EMPTY_WARNING;
	const [touched, setTouched] = useState({name:false,description:false});
	const [areaList, setAreaList] = useState([]);
	const [selectedArea, setSelectedArea] = useState([]);
	const [areaRoutes, setAreaRoutes] = useState([]);
	const [selectedAreaRoutes, setSelectedAreaRoutes] = useState([]);

	useEffect(() => {
		if(input.areas !== undefined) {
			setAreaList(input.areas);
		}
	},[input.areas]);
	
	useEffect(() => {
		if(!isNew && input.currentAreas !== null) {
			setAreaRoutes(input.currentAreas);
		}
	},[isNew, input.currentAreas]);

	const handleChangeData = (e) =>{
		const { value, name } = e.target
		onChangeAdminInput(name, value);
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		let valid = true;
		setTouched({name:true,description:true})

		let reqInputs = ["name", "description"]
		reqInputs.forEach((field, idx) => {
			if(input[field] === "" || input[field] === undefined) {
				valid = false;
			}
		});

		if(valid) {
			onSave();
		} else {
			alert(FORM_SUBMIT_EMPTY_WARNING);
		}
	}

	const handleSelectArea = (area) => {
		setSelectedArea(area);
	}
	
	const handleRoutedArea = (area) => {
		setSelectedAreaRoutes(area);
	}

	const handleShiftArea = () => {
		//bring (copy) selected from AreaList to AreaRoutes
		setSelectedAreaRoutes(selectedArea);
		let shifted = areaList.filter(area => selectedArea.includes(area.Id))
		//filter out selected
		let newAreaList = areaList.filter(function(val) {
			return shifted.indexOf(val) == -1;
		});
		//move selected AreaList to AreaRoutes
		let pushShifted = areaRoutes.concat(shifted) 
		setAreaRoutes(pushShifted);
		setAreaList(newAreaList);
		//update admin input
		onChangeAdminInput("currentAreas", pushShifted);

	}

	const handleUnshiftArea = () => {
		let unshifted = areaRoutes.filter(area => selectedAreaRoutes.includes(area.Id))
		let newAreaList = areaList.concat(unshifted);
		let newAreaRoutes = areaRoutes.filter(function(val) {
			return unshifted.indexOf(val) == -1;
		});
		setAreaList(newAreaList);
		setAreaRoutes(newAreaRoutes);
		setSelectedArea(selectedAreaRoutes);
		onChangeAdminInput("currentAreas", newAreaRoutes);
	}

	const handleMoveUp = () => {
		const indexPos = areaRoutes.findIndex(item => selectedAreaRoutes.includes(item.Id))
			let selected = areaRoutes.filter(area => selectedAreaRoutes.includes(area.Id));
			let newAreaRoutes = areaRoutes.filter(function(val) {
				return selected.indexOf(val) == -1;
			});
			if(indexPos > 0){
				newAreaRoutes.splice((indexPos-1),0, ...selected);
			}else{
				newAreaRoutes.splice((0),0, ...selected);
			}
			setAreaRoutes(newAreaRoutes);
	}

	const handleMoveDown = () => {
		const indexPos = areaRoutes.findIndex(item => selectedAreaRoutes.includes(item.Id));
		let selected = areaRoutes.filter(area => selectedAreaRoutes.includes(area.Id));
		let newAreaRoutes = areaRoutes.filter(function(val) {
			return selected.indexOf(val) == -1;
		});
		newAreaRoutes.splice((indexPos+1),0, ...selected);
		setAreaRoutes(newAreaRoutes);
	}

	const handleFieldWarning = (e) => {
		let field = e.target.name;
		setTouched((previousState => {
			return {...previousState,[field]:true}
		}))
	}
	//sort array of areas by "Priority" key
	const sortPriority = (arr) => {
		$.each(arr, (i, v) => {
			v.Priority = i + 1
		})
		return arr
	}
	const priotizedAreaRoutes = useMemo(()=> sortPriority(areaRoutes),[areaRoutes]);

	return (
		<form id="routingGroupsEdit" onSubmit={handleSubmit} className="admin-one-form edit-admin-form" hidden={hidden}>
			<div className='input-section'>
				<TextInputRow
					id={"name"}
					name={"name"}
					className="admin-text-input"
					label={I("Routing group Name")}
					value={input.name || ""}
					onChange={handleChangeData}
					mandatory={true}
					warning={(!input.name && touched.name) && warnTxt}
					inlineLabel={false}
					onBlur={handleFieldWarning}
				/>
				<TextInputRow
					id={"description"}
					name={"description"}
					className="admin-text-input"
					label={I("Description")}
					value={input.description || ""}
					onChange={handleChangeData}
					mandatory={true}
					warning={(!input.description && touched.description) && warnTxt}
					inlineLabel={false}
					onBlur={handleFieldWarning}
				/>
			</div>
			<div className='shift-list-container'>
				<div className='section-col'>
					<div className={"shift list-section"}>
						<div className='list-heading'>{I("List of Areas")}</div>
						<AreaDropdown
							id={"area-list"}
							data={areaList.sort((a,b)=> a.Id - b.Id)} //sortby ID acending
							idFields={{id: "Id", name: "Name"}}
							multiSelect={true}
							selected={selectedArea}
							onSelect={handleSelectArea}
							onToggle={()=>{}}
							selectAll={false}
							selectNone={false}
							overrideTitle={true}
							show={true}
							flip={false}
							offset={false}
							preventOverflow={false}
							modifiers={AreaRoutingMofo}
							textNoItemSelected={null}
							splitTitleAndLink={false}
							hideTitleLink={true}
							positionFixed={false}
							className={"routing-group-area-list"}
						/>
					</div>
					<div className="shift-buttons-container">
						<TableIconicButton
							type={"button"}
							onClick={handleShiftArea}
							className={"btn shift-button"}
							iconClass={"icon-chevron-right"}
							disabled={selectedArea.length <= 0}
						/>
						<TableIconicButton
							type={"button"}
							onClick={handleUnshiftArea}
							className={"btn shift-button"}
							iconClass={"icon-chevron-left"}
							disabled={selectedAreaRoutes.length <= 0}
						/>
					</div>
				</div>
				<div className='section-col'>
					<div className={"shift list-section"}>
						<div className='list-heading'>{I("Selected Areas")}</div>
						<AreaDropdown
							id={"area-routing"}
							data={priotizedAreaRoutes}
							idFields={{id: "Id", name: "Name"}}
							multiSelect={true}
							selected={selectedAreaRoutes}
							onSelect={handleRoutedArea}
							onToggle={()=>{}}
							selectAll={false}
							selectNone={false}
							overrideTitle={true}
							show={true}
							flip={false}
							offset={false}
							preventOverflow={false}
							modifiers={AreaRoutingMofo}
							textNoItemSelected={null}
							splitTitleAndLink={false}
							hideTitleLink={true}
							positionFixed={false}
							className={"routing-group-area-list"}
						/>
					</div>
					<div className="shift-buttons-container">
						<TableIconicButton
							type={"button"}
							onClick={handleMoveUp}
							className={"btn shift-button"}
							iconClass={"icon-chevron-up"}
							disabled={selectedAreaRoutes.length <= 0}
						/>
						<TableIconicButton
							type={"button"}
							onClick={handleMoveDown}
							className={"btn shift-button"}
							iconClass={"icon-chevron-down"}
							disabled={selectedAreaRoutes.length <= 0}
						/>
					</div>
				</div>
			</div>
			{baseButtons}
		</form>
	)
}

export default RoutingGroupsForm;