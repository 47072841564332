import { connect } from 'react-redux'
import {
	ADMIN_VIEW_MAP
	, emptyArray
	, M_ROUTING_GROUPS
} from '../../../common/v5/constants';
import {
	insertEditorContent
	, resetInsertionEditorContent
	, toggleAdminSidebar
	, changeAdminView
	, toggleAdminEdit
	, setAdminInput
	, resetAdminInput
} from '../../../redux/actions/admin';
//
import {
	adminAdmin
	, currentSortFieldMemo
	, currentSortOrder
	, getAdminListSelector
	, getAdminChannelSelector
	, listColumnSelector
	, getAdminListDefSelector
} from '../../../redux/selectors/admin';
import {
	openEditForm,
	fetchAdminList,
	setAdminData,
	removeAdminData,
	accountsAsyncs,
	sstAsyncs
} from '../../../redux/actions/async/admin';
import { orgAreaMemo } from '../../../redux/selectors/server'
import {
	togglePopWaiting
	, clearPopWaiting
	, popErrorOnly
} from '../../../redux/actions/hmf'
import { onlyActiveAreasSelector } from '../../../redux/selectors/workflow'
import { pleaseWaitString } from '../../../common/v5/helpers';
import { TXT_FETCHING_DATA, TXT_DELETING } from '../../../common/v5/chatbotConstants'
import RoutingGroups from './routinggroups'


const mapState = (store, props) => {
	const server = store.server
		, app = store.app
		, admin = app.admin
		, ui = admin.ui
		, adminSt = admin.admin
		, wf = app.workflow
		;
	return {
		view: ui.view,
		list: getAdminListSelector(store),
		langSrc: adminSt.langSrc,
		order: listColumnSelector(store),
		show: adminSt.show,
		showInline: adminSt.showInline,
		showList: adminSt.showList,
		activeId: adminSt.activeId,
		areaList: onlyActiveAreasSelector(store),
		input: adminSt.input,
		orgArea: orgAreaMemo(store),
		filter: adminSt.filter,
		adminStatus: adminSt.adminStatus,
	};
}

const mapDispatchToProp = (dispatch, props) => {
	return {
		onLoad: (view, freshLoad) => {
			dispatch(changeAdminView(view));
			dispatch(fetchAdminList(view));
		},
		onLoadToEdit: (id, view, popout, list) => {
			dispatch(resetAdminInput());
			dispatch(toggleAdminEdit(0, false, false, true));
			dispatch(togglePopWaiting(pleaseWaitString(TXT_FETCHING_DATA)));
			dispatch(sstAsyncs[M_ROUTING_GROUPS].one(id))
				.then((rs) => {
					dispatch(clearPopWaiting());
					dispatch(toggleAdminEdit(id, true, false, true));
				})
				.catch(err => {
					console.log("error getting routing groups edits:", err);
					dispatch(clearPopWaiting());
					return dispatch(popErrorOnly(err));
				})
		},
		onChangeAdminInput: (field, value, key) => {
			dispatch(setAdminInput(field, value, key));
		},
		onSaveAdmin: (view, createNew, id, input) => {
			dispatch(setAdminData(view, createNew, id, input));
		},
		onDeleteFromList: (id, view) => {
			dispatch(togglePopWaiting(pleaseWaitString(TXT_DELETING)));
			dispatch(sstAsyncs[M_ROUTING_GROUPS].remove(id))
			.then((rs) => {
				dispatch(fetchAdminList(M_ROUTING_GROUPS));
				dispatch(toggleAdminEdit(0, false, false, true));
				dispatch(clearPopWaiting());
			})
			.catch(err => {
				console.log("error deleting routing group: ", err);
				dispatch(clearPopWaiting());
				return dispatch(popErrorOnly(err));
			});
		},
		onCloseForm: () => {
			dispatch(resetAdminInput());
			dispatch(toggleAdminEdit(0, false, false, true));
		},
	}
};

const RoutingGroupsCtnr = connect(mapState, mapDispatchToProp)(RoutingGroups)

export default RoutingGroupsCtnr

