import { connect } from "react-redux";
import Skills from "./Skills";
import {
	getAdminListSelector
	, skillCategorySelector
	, skillProficiencySelector
	, skillAdminAgentListSelector
} from '../../../redux/selectors/admin';
import {
	changeAdminView
	, toggleAdminEdit
	, setAdminInput
	, resetAdminInput
	, getAdminDataFromList
	, adminActionAlert
} from '../../../redux/actions/admin';
import {
	saveSkillsCategory
	, saveSkills
	, saveAgentSkill
	, fetchSkillsCategory
	, fetchSkills
	, removeSkillCategory
	, removeSkill
	, agentList
	, fetchSkillProficiency
	, removeSkillAgent
	, fetchSkillAgents
} from '../../../redux/actions/async/admin';
import { M_SKILLS } from "../../../common/v5/constants";

const mapSkillsState = (store, props) => {
	const server = store.server
		, app = store.app
		, admin = app.admin
		, ui = admin.ui
		, adminSt = admin.admin
		, wf = app.workflow
		;
	return {
		view: ui.view
		, list: getAdminListSelector(store)
		, agentPerSkillList: skillAdminAgentListSelector(store)
		, categoryList: skillCategorySelector(store)
		, proficiencyList: skillProficiencySelector(store)
		, agentList: admin.agentList.data ? admin.agentList.data : []
		, showInline: adminSt.showInline
		, activeId: adminSt.activeId
		, input: adminSt.input
		, adminAlert: adminSt.adminAlert
	};
};

const mapSkillsAction = (dispatch, props) => {
	return {
		onLoad: (view, freshLoad) => {
			dispatch(changeAdminView(view));
			dispatch(fetchSkillsCategory());
			dispatch(fetchSkills());
			dispatch(fetchSkillProficiency());
		},
		onLoadToEdit: (id, view, popout, list) => {
			dispatch(agentList());
			if (id === 0) {
				dispatch(toggleAdminEdit(0, true, false, list));
				dispatch(resetAdminInput());
			} else {
				if (typeof list !== "undefined") {
					dispatch(getAdminDataFromList(id, list, view));
				}
				dispatch(toggleAdminEdit(id, true, false, list));
				dispatch(fetchSkillAgents(id));
			}
		},
		onChangeAdminInput: (field, value) => {
			dispatch(setAdminInput(field, value, M_SKILLS));
		},
		onCancel: () => {
			dispatch(toggleAdminEdit(0, false, false, true));
		},
		onDeleteFromList: (id, view, list) => {
			dispatch(removeSkill(id));
		},
		onDeleteCategory(id) {
			dispatch(removeSkillCategory(id));
		},
		onCloseForm: () => {
			dispatch(toggleAdminEdit(0, false, false, true));
		},
		onDismissAlert: () => {
			const alertMsg = {
				show: false,
				msg: "",
				type: ""
			}
			dispatch(adminActionAlert(alertMsg));
		},
		onSaveCategory: (param) => {
			dispatch(saveSkillsCategory(param));
		},
		onSaveSkills: (param) => {
			dispatch(saveSkills(param));
		},
		onSaveAgentIntoSkill: (param) => {
			dispatch(saveAgentSkill(param));
		},
		onDeleteAgentSkill(param) {
			dispatch(removeSkillAgent(param));
		}
	}
};

export const SkillsCtnr = connect(
	mapSkillsState
	, mapSkillsAction
)(Skills);